import { createMedia } from "@artsy/fresnel";
import React, { Component } from "react";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  Segment,
} from "semantic-ui-react";
import NextLevel from "../components/NextLevel";
import SolutionAccordion from "../components/SolutionAccordion";
import "../styles/queries.css";
import "../assets/mobile/Home.css"
import { useMediaQuery } from 'react-responsive'

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
});

export function Home_jp() {
  const isMobile = useMediaQuery({query: '(max-width: 768px)'})

  return (<>

        <Container>
          <Grid container stackable verticalAlign="middle">
            <Grid.Row columns={2}>
              <Grid.Column width={7}>
                <div className="mobileAiImageCont" style={{marginTop: "3em"}}> 
                  <Image src="/muse.png" />
                </div>
                <h1 className='mainTopHeader'>AI Communication Engine</h1> 
                {/* <Divider hidden /> */}
                <p className="mainTopContent">
                  Museはディープラーニング、ビックデーター、NLP技術とマシンヴィジョンを利用し、教育やヘルスケア、農業などの分野に必要な機能をご提供します。
                </p>
                {
                !isMobile &&
                  <>
                  <Divider hidden />{" "}
                  <Divider hidden />{" "}
                  <Divider hidden />{" "}
                  </>
                }
                {
                isMobile &&
                <Grid.Column width={9}>
                  <Image src="/homepagelogo.png" width='310px' style={{margin:"auto"}}/>
                </Grid.Column>
                }

                <div className='mainButtonContTop'>
                <Button  href="/sales/" color="orange" className="huge ui button" >
                  お問い合わせ
                </Button>
                {/* <Button
                  href="https://apps.apple.com/kr/app/april%EB%B9%84%EB%82%98%ED%86%A1/id1486317712"
                  inverted
                  color="orange"
                  className="huge ui button"
                >
                  Start a free trial
                </Button> */}

                </div>
               
              </Grid.Column>
              {!isMobile &&
                <Grid.Column width={9}>
                  <Image src="/homepagelogo.png"/>
                </Grid.Column>
              }
              
            </Grid.Row>
          </Grid>
        </Container>
        {
          !isMobile &&
            <>
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            </>
        }

        { isMobile &&<Divider hidden />}


        <Grid
          celled="internally"
          columns="equal"
          stackable
          style={{ padding:isMobile ? "1em 0em" :  "4em 0em", backgroundImage: "url(/watchvid.png)" }}
        >
          <Grid.Row textAlign="center">
            <Grid.Column style={{ paddingBottom: "5em", paddingTop: "5em" }}>
              <Header as="h3" style={{ fontSize: "2em" }}>
              <p className="mobileMainTextWidth">
                MuseのAI APIで競合他社との差別化を図ることができます
              </p>
              </Header>
              <Divider hidden />
              <Divider hidden />
              <Divider hidden />
              {/* <a className="mobileLinkMainWatchVideo" href="#" style={{ color: "#F2711C" }}>Watch a video <Icon name="arrow right" /></a> */}
              <p className="mobileSubTextWidth" style={{color:"#F2711C"}}>あなたのビジネスにリアルなAIコミュニケーションと認知を活用できます</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {
          !isMobile &&
            <>
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            </>
        }

      {
          isMobile &&
            <>
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            </>
        }

        <Grid container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header as="h3">MUSE API 01</Header>
              <Header as="h1">MUSE AI 分析API</Header>
              <p style={{fontSize:"24px"}}>
                Muse 分析はユーザーの会話を分析します。文章の構造やパターンを認識し、ユーザーの会話の多様性を分析します。会話の文章、単語、発音、さらには文脈を評価します。
              </p>
              <a className="mobileLinkMainWatchVideo" href="/jp/ai/analysis/" style={{ color: "#F2711C" }}>詳しくはこちら <Icon name="arrow right" /></a>
            </Grid.Column>
            { !isMobile &&
              <Grid.Column>
              <Image src="/introanalysis.png"/>
              </Grid.Column>
            }
          </Grid.Row>
        </Grid>

        { !isMobile &&
        <div>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        </div>
        }

      { isMobile &&
        <div>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        </div>
        }
        

        <Grid className='mobileVersionMuseApi' container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            { !isMobile &&
              <Grid.Column>
                <Image src="/mobilechat.png"/>
              </Grid.Column>
            }
            <Grid.Column>
              <Header as="h3">MUSE API 02</Header>
              <Header as="h1">MUSE AIチャットAPI</Header>
              <p className="changeTextWidth">
              Museチャットは音声認識および音声合成テクノロジーを使用し、音声をテキストに、またはテキストを音声に変換します。教科書や教育コンテンツを活用してチャットボットを提供したり、音声による応答を提案し、英語の授業をリードします。
              </p>
              <a  className='mobileLinkMainWatchVideo' href="/jp/ai/chat/" style={{ color: "#F2711C" }}>詳しくはこちら<Icon name="arrow right" /></a>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        { !isMobile &&
        <div>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        </div>
        }

      { isMobile &&
        <div>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        </div>
        }
        
        
        <Grid className='mobileVersionMuseApi' container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header as="h3">MUSE API 03</Header>
              <Header as="h1">MUSE AIヴィジョンAPI</Header>
              <p className="changeTextWidth">
              Muse ヴィジョンは、ユーザーとその表情を視覚的に認識します。また、物を識別し、植物と果物の区別や植物の状態、収穫への適合性を識別します。
              </p>

              <a className='mobileLinkMainWatchVideo' href="/jp/ai/vision/" style={{ color: "#F2711C" }}>詳しくはこちら <Icon name="arrow right" /></a>

            </Grid.Column>
            { !isMobile &&
            <Grid.Column>
              <Image src="/homevision.png"/>
            </Grid.Column>
            }
          </Grid.Row>
        </Grid>

        { !isMobile &&
        <div>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        </div>
        }

      { isMobile &&
        <div>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        </div>
        }
        

        <Header as="h1" textAlign="center" style={{ fontSize: "3em" }}>
          活用分野
        </Header>

        { !isMobile &&
        <div>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        </div>
        }

        <SolutionAccordion isMobile={isMobile} />

        <Divider hidden />
        <Divider hidden />




        <Segment
          style={{
            padding: isMobile ? "6em 1em" : "8em",
            border: 0,
            backgroundColor: "#f7f7f8",
          }}
          vertical
        >
          <Grid container stackable verticalAlign="middle">
            <Grid.Row columns={2}>
              <Grid.Column>
                <Header style={{fontSize: "15px", margin:"0px",  color: "#6B7480"}} as="h3">Muse APIの成功事例</Header>
                <Header as="h1">Chungdahm Learning Vena Talk</Header>
                <p style={{fontSize:"24px"}}>
                  VENA TALKはMuseを利用した子供向けの英語教育アプリで、無制限でフリーチャットをすることができます。練習、ロールプレイ、フリートークの3つのステップで、生徒は表現、話し方のパターンを学び、自由に話すことができます。
                </p>

              {!isMobile &&<a
                href="https://apps.apple.com/kr/app/april%EB%B9%84%EB%82%98%ED%86%A1/id1486317712"
                style={{ color: "#F2711C",fontSize:"24px" }}
              >
                詳細を確認する <Icon name="arrow right" />
              </a>}
              {isMobile &&
                <div>
              <Divider hidden />
              <Divider hidden />
              <Divider hidden />
              </div>}

              {isMobile && <a className="mobileLinkMainWatchVideo" href="#" style={{ color: "#F2711C"}}>Watch a video <Icon name="arrow right" /></a>}


              </Grid.Column>
              {!isMobile && <Grid.Column>
                <Image src="/edu5.png"/>
                <Image src="/edu4.png"/>
              </Grid.Column>}
            </Grid.Row>
          </Grid>

          {!isMobile &&
          <Grid stackable style={{ paddingTop: isMobile ? "1em" : "8em" }} centered columns={2}>
            <Grid.Row columns={3}>
              <Grid.Column textAlign="center">
                <Image width={isMobile ? "83px" : 'auto'} src="/logo_softbank.png" />
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Image  width={isMobile ? "83px" : 'auto'} src="/logo_kddi.png" />
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Image  width={isMobile ? "61px" : 'auto'} src="/logo_speecys.png" />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={3}>
              <Grid.Column>
                <Image   width={isMobile ? "109px" : 'auto'}  src="/logo_rakuten.png" />
              </Grid.Column>
              <Grid.Column>
                <Image  width={isMobile ? "94px" : 'auto'}  src="/logo_kanji.png" />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          }
          {isMobile &&
          <div style={{display:"flex" , marginTop:"46px", flexWrap:"wrap" , columnGap:"38px", justifyContent:"center" , rowGap:"35px"}}>
                <Image width={isMobile ? "83px" : 'auto'} src="/logo_softbank.png" />
                <Image  width={isMobile ? "83px" : 'auto'} src="/logo_kddi.png" />
                <Image  width={isMobile ? "61px" : 'auto'} src="/logo_speecys.png" />
                <Image   width={isMobile ? "109px" : 'auto'}  src="/logo_rakuten.png" />
                <Image  width={isMobile ? "94px" : 'auto'}  src="/logo_kanji.png" />
          </div>
          }
        </Segment>


          {!isMobile &&

            <div>
              <Divider hidden />
              <Divider hidden />
              <Divider hidden />
              <Divider hidden />
              <Divider hidden/>
              <Divider hidden/>
              <Divider hidden/>
              <Divider hidden/>
            </div>
          }
        

        <NextLevel />
      </>
  );
}



