import { createMedia } from "@artsy/fresnel";
import React, { Component } from "react";
import {
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  Segment,
} from "semantic-ui-react";
import NextLevel from "../components/NextLevel";
import { useMediaQuery } from 'react-responsive'

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
});
export function SolutionHealth_jp() {
  const isMobile = useMediaQuery({query: '(max-width: 768px)'})

    return (
      <>
        <Segment
          style={{
            border: 0,
            padding: "0em 0em 0em 0em",
            backgroundColor: "#ffeee4",
          }}
          vertical
        >
          {!isMobile &&
          <Grid container stackable verticalAlign="middle">
            <Grid.Row style={{ height: "500px" }} columns={1}>
              <Grid.Column width={8}>
                <Header style={{fontSize: '28px', color: '#F37021', marginTop:"50px"}}>
                ヘルスケア
                </Header>
                <Header style={{fontSize: '48px'}}>
                介護者を補う
                </Header>
                <Button style={{marginTop:'40px'}}color="orange" className="huge ui button" href="/sales/">お問い合わせ</Button>
              </Grid.Column>
              <Grid.Column style={{ marginLeft: "200px", marginTop:"-300px"}}>
                  <Image src="/healthsvg.png" style={{width:'1000px', height:'536px', marginTop:'50px'}} />
                </Grid.Column>
              </Grid.Row>
          </Grid>
          }

          {isMobile &&
            <Grid container stackable verticalAlign="middle">
            <Grid.Row columns={2}>
              <Grid.Column style={{textAlign:"center"}}>
                <Header as="h3" style={{marginTop:"15px", textAlign:"center"}}>ヘルスケア </Header>
                <Header style={{ fontSize: '28px' }}>
                介護者を補う
                </Header>
                <Image style={{width:"311px", margin:"auto"}} src="/health-mobile.png" />
                <Button style={{marginTop:'40px'}} color="orange" className="huge ui button" href="/sales/">お問い合わせ</Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        } 
        </Segment>

        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />

        <Header as="h1"  className='pageTopParth1' textAlign="center" style={{marginTop:"100px" }}>
        ヘルスケア
        </Header>

        <Divider hidden />

        <Grid container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Image src="/health1.png" />
            </Grid.Column>

            <Grid.Column>
              <Header as="h3" style={{marginTop:"100px"}}>ヘルスケア</Header>
              <Header as="h1">ReSmart</Header>
              <p style={{fontSize:"24px"}}>
              認知機能向上のために設計された脳トレーニングプログラムと瞑想、おすすめのルーティンでAIライフコーチングをご提供します。
              <br/><br/>
              5つの主な認知機能を改善します。<br/><br/>
              ストレスとその結果としてのうつ病のリスクを和らげる<br/><br/>
              
                あなたの体と脳を健康に保つための毎日のルーティンを提供<br/><br/>
              
              改善点を追跡 </p>

              <a href="https://akaintelligence.com/jp/resmart" style={{ color: "#F2711C" , fontSize:"24px"}}>
              詳しくはこちら <Icon name="arrow right" />
              </a>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Divider hidden />

        <Divider hidden />
        <Divider hidden />

        <Grid container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column style={{marginTop:"100px"}}>
              <Header as="h3">ヘルスケア</Header>
              <Header as="h1">Musio Alive X 場面緘黙症</Header>
              <p style={{fontSize:"24px"}}>
              AIロボットの使用が障害のある生徒の教育に役立つことが、日本の中学校で検証されました。実験の結果、生徒がAIロボットによって場面緘黙症を克服し、より自由にコミュニケーションできることがわかりました。
              </p>
              <p style={{fontSize:"24px"}}>
              外国語の学習に消極的であった生徒は、学習にもっと興味を持つようになりました。生徒たちは発音の練習にもっと集中できるようになり、彼らの受講態度も改善されました。
              </p>
              <a href="https://akaintelligence.com/jp/resmart" style={{ color: "#F2711C" ,fontSize:"24px" }}>
              詳しくはこちら <Icon name="arrow right" />
              </a>
            </Grid.Column>

            <Grid.Column>
              <Image src="/health2.png" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Divider hidden />

        <Divider hidden />
        <Divider hidden />

        <NextLevel />
      </>
    );
  
}
