import React, { Component } from "react";
import AliceCarousel from "react-alice-carousel";
import { connect } from "react-redux";
import {
  Container,
  Divider,
  Grid,
  Header,
  Image,
  Tab,
} from "semantic-ui-react";
import Visualization from "../components/AIAnalysisVisualization";
import NextLevel from "../components/NextLevel";
import { fetchAnalysis } from "../redux/actions/actions";
import "../assets/global/components/aiAnalaysis.css"

const panes = [
  {
    menuItem: "예시",
    render: () => (
      <Tab.Pane style={{ padding: 40 }}>
        <Header as="h1" style={{ fontSize: "2em" }}>
          Muse API를 활용한 다른 어플에서의 예시
        </Header>
        <Container>
          <Header as="h1" style={{ fontSize: "1.5em" }} textAlign="center">
            Muse 잉글리시
          </Header>

          <AliceCarousel autoPlay autoPlayInterval="3000">
            <Image rounded raised src={"/analysis_example_3.png"} />
            <Image rounded raised src={"/analysis_example_2.png"} />
            <Image rounded raised src={"/analysis_example_4.png"} />
            <Image rounded raised src={"/analysis_example_1.png"} />
          </AliceCarousel>
          {/* 
          <Image.Group>
            <Image src="/analysis_example_3.png" />
            <Image src="/analysis_example_2.png" />
            <Image src="/analysis_example_4.png" />
            <Image src="/analysis_example_1.png" />
          </Image.Group> */}
        </Container>
      </Tab.Pane>
    ),
  },
  {
    menuItem: "채팅 로그에서 불러오기",
    render: () => (
      <Tab.Pane>
        <Visualization />
      </Tab.Pane>
    ),
  },
];

export class AIAnalysis_kr extends Component {
  componentDidMount() {
    this.props.dispatch(fetchAnalysis());
  }
  render() {
    return (
      <>
        <div style={{ backgroundColor: "#f1f1f1" }}>
          <Container textAlign="center" style={{ paddingTop: "3em" }}>
            <h3 className="aiAnlyzPage3">MUSE API 01</h3>
            <h1 className="aiAnlyzPage1">
              MUSE AI 분석 API
            </h1>
            {/* <p>지금 MUSE AI분석 API를 체험해 보세요</p> */}
          </Container>

          <Container style={{ padding: "2em" }}>
            <Tab panes={panes} />

            {/* <Segment raised style={{ paddingTop: 40 }}>
              <Visualization />
            </Segment> */}
          </Container>
        </div>
        <Divider hidden />
        <Divider hidden />
        <h1 className="aiAnlyzPage1" style={{textAlign:"center" , marginTop:"100px"}}>
          Muse AI 분석 API  <div className='fullWidthMobileBlock'></div> (선택됨)
        </h1>
        <div className="mobileDiveder"></div>

        <div className="aiAnlyzPageElementsDown" >
          <div className="aiAnalyzeMobileBlock">
          <Grid container stackable verticalAlign="top">
            <Grid.Row columns={3}>
              <Grid.Column>
                <Image width="90px" src="/analysis1.jpg" />
                <Header style={{fontSize:"28px"}}>독해 능력 측정</Header>
                <p style={{fontSize:"24px"}}>
                  발화 문장의 난이도를 측정함으로써 사용자의 독해 능력을 평가
                </p>
                <div className="mobileDiveder"></div>
                <div className="mobileDiveder"></div>
                <div className="mobileDiveder"></div>

              </Grid.Column>

              <Grid.Column>
                <Image width="90px" src="/analysis2.png" />
                <Header style={{fontSize:"28px"}}>
                  문장의 다양성 및 맥락의 연관성 분석
                </Header>
                <p style={{fontSize:"24px"}}>
                  사용자 스피치 패턴에서 보이는 다양성의 정도를 분석.{" "}
                </p>
                <p style={{fontSize:"24px"}}>사용자의 답변과 대화 맥락 사이의 연관성을 분석</p>
                <div className="mobileDiveder"></div>
                <div className="mobileDiveder"></div>
                <div className="mobileDiveder"></div>
              </Grid.Column>
              <Grid.Column>
                <Image width="90px" src="/analysis3.png" />
                <Header style={{fontSize:"28px"}}>문장 길이</Header>
                <p style={{fontSize:"24px"}}>정해진 표준에서 문장 길이를 측정</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          </div>


          <div className="mobileDiveder"></div>

          <Grid container stackable verticalAlign="top">
            <Grid.Row columns={3}>
              <Grid.Column>
                <Image width="90px" src="/measurement3.png" />
                <Header style={{fontSize:"28px"}}>문법 체크 API</Header>
                <p style={{fontSize:"24px"}}>
                  발화된 문장 속 문법적 오류 감지
                </p>
                <div className="mobileDiveder"></div>
                <div className="mobileDiveder"></div>
                <div className="mobileDiveder"></div>
              </Grid.Column>
              <Grid.Column>
                <Image width="90px" src="/analysis5.png" />
                <Header style={{fontSize:"28px"}}>
                  연관성 점수 측정
                </Header>
                <p style={{fontSize:"24px"}}>
                  사용자 답변이 주어진 질문에 얼마나 정확히 관련되어 있는지 연관성 점수를 줌
                </p>
                <div className="mobileDiveder"></div>
                <div className="mobileDiveder"></div>
                <div className="mobileDiveder"></div>
              </Grid.Column>
              <Grid.Column>
                <Image width="90px" src="/analysis6.png" />
                <Header style={{fontSize:"28px"}}>발화 문장를 부분으로 나누어 추출</Header>
                <p>사용자의 말에 들어간 각각의 단어를 구분 및 추출</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <Divider hidden />
        <Divider hidden />

        <NextLevel />
      </>
    );
  }
}

AIAnalysis_kr = connect((store) => {
  return {
    data: store.data,
  };
})(AIAnalysis_kr);
