import { createMedia } from "@artsy/fresnel";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Footer from "./components/Footer";
import Footer_kr from "./kr/components/Footer";
import Footer_jp from "./jp/components/Footer";
import Footer_cn from "./cn/components/Footer";

import Topbar from "./components/Topbar";
import Topbar_kr from "./kr/components/Topbar";
import Topbar_jp from "./jp/components/Topbar";
import Topbar_cn from "./cn/components/Topbar";

import { AIAnalysis } from "./layouts/AIAnalysis";
import { AIAnalysis_kr } from "./kr/layouts/AIAnalysis"; 
import { AIAnalysis_jp } from "./jp/layouts/AIAnalysis";
import { AIAnalysis_cn } from "./cn/layouts/AIAnalysis";

import { AIChat } from "./layouts/AIChat";
import { AIChat_kr } from "./kr/layouts/AIChat";
import { AIChat_jp } from "./jp/layouts/AIChat";
import { AIChat_cn } from "./cn/layouts/AIChat";

import { AIVision } from "./layouts/AIVision";
import { AIVision_kr } from "./kr/layouts/AIVision";
import { AIVision_jp } from "./jp/layouts/AIVision";
import { AIVision_cn } from "./cn/layouts/AIVision";

import { Home } from "./layouts/Home";
import { Home_kr} from "./kr/layouts/Home";
import { Home_jp } from "./jp/layouts/Home";
import { Home_cn } from "./cn/layouts/Home";

import { Pricing } from "./layouts/Pricing";
import { Pricing_kr } from "./kr/layouts/Pricing";
import { Pricing_jp } from "./jp/layouts/Pricing";
import { Pricing_cn } from "./cn/layouts/Pricing";

import { SolutionEducation } from "./layouts/SolutionEducation";
import { SolutionEducation_kr } from "./kr/layouts/SolutionEducation";
import { SolutionEducation_jp } from "./jp/layouts/SolutionEducation";
import { SolutionEducation_cn } from "./cn/layouts/SolutionEducation";

import { SolutionEnterprise } from "./layouts/SolutionEnterprise";
import { SolutionEnterprise_kr } from "./kr/layouts/SolutionEnterprise";
import { SolutionEnterprise_jp } from "./jp/layouts/SolutionEnterprise";
import { SolutionEnterprise_cn } from "./cn/layouts/SolutionEnterprise";

import { SolutionHealth } from "./layouts/SolutionHealth";
import { SolutionHealth_kr } from "./kr/layouts/SolutionHealth";
import { SolutionHealth_jp } from "./jp/layouts/SolutionHealth";
import { SolutionHealth_cn } from "./cn/layouts/SolutionHealth";

import { SolutionMobility } from "./layouts/SolutionMobility";
import { SolutionMobility_kr } from "./kr/layouts/SolutionMobility";
import { SolutionMobility_jp } from "./jp/layouts/SolutionMobility";
import { SolutionMobility_cn } from "./cn/layouts/SolutionMobility";

import { TalkToSales } from "./layouts/TalkToSales";
import { TalkToSales_kr } from "./kr/layouts/TalkToSales";
import { TalkToSales_jp } from "./jp/layouts/TalkToSales";
import { TalkToSales_cn } from "./cn/layouts/TalkToSales";

import "./assets/global/styles.css"

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
});

export default function App() {
  return (
    <Router>
      {" "}
      <div>
        {/* { window.location.pathname.includes('/kr') ? (<Topbar_kr/>) : 
          (window.location.pathname.includes('/jp') ? (<Topbar_jp/>) :
          (window.location.pathname.includes('/cn') ? (<Topbar_cn/>) : (<Topbar/>)))
        } */}
        { window.location.pathname.includes('/kr') ? (<Topbar_kr/>) : 
        (window.location.pathname.includes('/jp') ? (<Topbar_jp/>) : 
        (window.location.pathname.includes('/cn') ? (<Topbar_cn/>) : (<Topbar/>))) }
          <Switch>

            {/* english site */}
            <Route
              exact
              path="/ai/analysis/"
              render={(props) => <AIAnalysis {...props} />}
            />
            <Route
              exact
              path="/ai/chat/"
              render={(props) => <AIChat {...props} />}
            />
            <Route
              exact
              path="/ai/vision/"
              render={(props) => <AIVision {...props} />}
            />

            <Route
              exact
              path="/solution/mobility/"
              render={(props) => <SolutionMobility {...props} />}
            />
            <Route
              exact
              path="/solution/enterprise/"
              render={(props) => <SolutionEnterprise {...props} />}
            />
            <Route
              exact
              path="/solution/education/"
              render={(props) => <SolutionEducation {...props} />}
            />
            <Route
              exact
              path="/solution/health/"
              render={(props) => <SolutionHealth {...props} />}
            />
            <Route
              exact
              path="/pricing/"
              render={(props) => <Pricing {...props} />}
            />
            <Route
              exact
              path="/sales/"
              render={(props) => <TalkToSales {...props} />}
            />
            <Route exact path="/" render={(props) => <Home {...props} />} />
          </Switch>
      </div>
      
      <div>
       
        <Switch>

          {/* korea site */}
          <Route
            exact
            path="/kr/ai/analysis/"
            render={(props) => <AIAnalysis_kr {...props} />}
          />
          <Route
            exact
            path="/kr/ai/chat/"
            render={(props) => <AIChat_kr {...props} />}
          />
          <Route
            exact
            path="/kr/ai/vision/"
            render={(props) => <AIVision_kr {...props} />}
          />

          <Route
            exact
            path="/kr/solution/mobility/"
            render={(props) => <SolutionMobility_kr {...props} />}
          />
          <Route
            exact
            path="/kr/solution/enterprise/"
            render={(props) => <SolutionEnterprise_kr {...props} />}
          />
          <Route
            exact
            path="/kr/solution/education/"
            render={(props) => <SolutionEducation_kr {...props} />}
          />
          <Route
            exact
            path="/kr/solution/health/"
            render={(props) => <SolutionHealth_kr {...props} />}
          />
          <Route
            exact
            path="/kr/pricing/"
            render={(props) => <Pricing_kr {...props} />}
          />
          <Route
            exact
            path="/kr/sales/"
            render={(props) => <TalkToSales_kr {...props} />}
          />
          <Route exact path="/kr/" render={(props) => <Home_kr {...props} />} />
        </Switch>
        
      </div>

      <div>
       
       <Switch>

         {/* japan site */}
         <Route
           exact
           path="/jp/ai/analysis/"
           render={(props) => <AIAnalysis_jp {...props} />}
         />
         <Route
           exact
           path="/jp/ai/chat/"
           render={(props) => <AIChat_jp {...props} />}
         />
         <Route
           exact
           path="/jp/ai/vision/"
           render={(props) => <AIVision_jp {...props} />}
         />

         <Route
           exact
           path="/jp/solution/mobility/"
           render={(props) => <SolutionMobility_jp {...props} />}
         />
         <Route
           exact
           path="/jp/solution/enterprise/"
           render={(props) => <SolutionEnterprise_jp {...props} />}
         />
         <Route
           exact
           path="/jp/solution/education/"
           render={(props) => <SolutionEducation_jp {...props} />}
         />
         <Route
           exact
           path="/jp/solution/health/"
           render={(props) => <SolutionHealth_jp {...props} />}
         />
         <Route
           exact
           path="/jp/pricing/"
           render={(props) => <Pricing_jp {...props} />}
         />
         <Route
           exact
           path="/jp/sales/"
           render={(props) => <TalkToSales_jp {...props} />}
         />
         <Route exact path="/jp/" render={(props) => <Home_jp {...props} />} />
       </Switch>
     </div>

     <div>
       
       <Switch>

         <Route
           exact
           path="/cn/ai/analysis/"
           render={(props) => <AIAnalysis_cn {...props} />}
         />
         <Route
           exact
           path="/cn/ai/chat/"
           render={(props) => <AIChat_cn {...props} />}
         />
         <Route
           exact
           path="/cn/ai/vision/"
           render={(props) => <AIVision_cn {...props} />}
         />

         <Route
           exact
           path="/cn/solution/mobility/"
           render={(props) => <SolutionMobility_cn {...props} />}
         />
         <Route
           exact
           path="/cn/solution/enterprise/"
           render={(props) => <SolutionEnterprise_cn {...props} />}
         />
         <Route
           exact
           path="/cn/solution/education/"
           render={(props) => <SolutionEducation_cn {...props} />}
         />
         <Route
           exact
           path="/cn/solution/health/"
           render={(props) => <SolutionHealth_cn {...props} />}
         />
         <Route
           exact
           path="/cn/pricing/"
           render={(props) => <Pricing_cn {...props} />}
         />
         <Route
           exact
           path="/cn/sales/"
           render={(props) => <TalkToSales_cn {...props} />}
         />
         <Route exact path="/cn/" render={(props) => <Home_cn {...props} />} />
       </Switch>
       { window.location.pathname.includes('/kr') ? (<Footer_kr/>) : 
        (window.location.pathname.includes('/jp') ? (<Footer_jp/>) : 
        (window.location.pathname.includes('/cn') ? (<Footer_cn/>) : (<Footer/>))) }
     </div>


    </Router>
  );
}
