import { createMedia } from "@artsy/fresnel";
import React, { Component } from "react";
import { Button, Card, Divider, Header, Image, List } from "semantic-ui-react";
import NextLevel from "../components/NextLevel";
import "../assets/global/components/pricingPage.css"
import { useMediaQuery } from 'react-responsive'
const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
});


export function Pricing_cn() {
  const isMobile = useMediaQuery({query: '(max-width: 768px)'})

    return (
      <>
        <div style={{ padding: isMobile? "1em" : "4em" }} vertical>
          <Header as="h1" textAlign="center" style={{ fontSize: "48px" }}>
          ご利用料金
            <Header.Subheader style={{fontSize:"24px", marginTop:"20px"}}>
            Muse APIのプランを選択ください
            </Header.Subheader>
          </Header>
          <Divider hidden />

          <div className="cardsCont">



            <div className='pricingCard' color="orange">
                <div className="cardTopOrangeLine"></div>
                <h4 className='pricingCardHeader'>個別購入価格 </h4>
                <br />
                <Card.Meta style={{fontSize:"42px", fontWeight:"700"}}>API当たり</Card.Meta>
                <br />
              

                <Card.Description>
                  <Header as="h1" className="pricingCardList2" style={{ fontSize: "42px"}}>
                    $7
                  </Header>
                </Card.Description>
                <hr className='cardLine' style={{marginTop:isMobile? "":"50px"}}/>
                <br />
                <p className='cardText'>
                  {" "}
                  <p>複数のAPIをご利用いただく場合は、各小売価格の50％のみご請求させていただきます。</p>
                </p>

                <div style={{marginTop: isMobile? "100px" : "50px" , width:"100%" , height:"54"}}>
                  <Button  className="pricingButton" href="/ai/chat/" fluid color="orange" inverted>
                    <div className="pricingButtonDiv">
                    無料体験を始める
                    </div>
                  </Button>
                </div>
            </div>




            <div className='pricingCard' color="orange">
              <div className="cardTopOrangeLine"></div>
          

                <div>
                  <h4 className='pricingCardHeader'>パッケージ購入 </h4>
                </div>
                <br />
                <Card.Meta style={{fontSize:"42px", fontWeight:"700"}}>お問い合わせ</Card.Meta>
                <br />
                {/* <div style={{display:"flex" , flexDirection:"column" , marginTop:"12px", fontSize:"18px"}}>
                  <Header as="h1" style={{color:'black'}} style={{margin:"0px" , color:"black"}}>Contact Us</Header>
                </div> */}
                <Card.Description>
                  <ul className='pricingCardList'>
                      <li>英語教育APIパッケージ</li>
                      <li>
                      デジタル治療API（認知障害）パッケージ
                      </li>
                      <li>
                      未来のコネックテッドカー向けの同行者（ドライビングコンパニオン）APIパッケージ
                      </li>
                      <li>スマートファーミングAPIパッケージ</li>
                  </ul>
                </Card.Description>

                <hr className='cardLine' style={{marginTop:isMobile? "100px" : "50px"}}/>
                <br />
                <div className='cardText' style={{color:"#6B7480", fontSize:"24px",  lineHeight:"150%", height:isMobile? "150px" : ""}}>ご利用料金やパッケージについてご不明点がございましたら、お問い合わせください</div>
                {" "}

                <div style={{marginTop:isMobile ? "20px" :"70px" , width:"100%", height:"54"}}>
                  <Button  className="pricingButton " href="/sales/" fluid color="orange" >
                    <div className="pricingButtonDiv">
                    お問い合わせ
                      </div>
                  </Button>
                </div>
                <div>
                  
                </div>
            </div>





          </div>
        </div>{" "}


        <Divider hidden />
        <Divider hidden />


        <div style={{ padding: isMobile ? "40px 32px" : "80px 80px", width:"100%", boxSizing:"border-box", backgroundColor: "#ffeee4" , display:'flex' ,flexDirection:"column" , alignItems:"center"}} vertical>
          <Header as="h1" textAlign="center" style={{ fontSize: "42px" }}>
          教育APIパッケージ
          </Header>
          <Divider hidden />
          <Divider hidden />
          <div style={{margin:"auto" , display:"flex" , columnGap:"48px" , flexWrap:"wrap" ,   rowGap:"20px",}}>
            <div className='eduApiSectionCard' style={{ width: isMobile ? "100%" : 500, boxSizing:"border-box" , height:isMobile ? "auto" : "450px"  }}>
                <h4 className='eduApiCardHeader'>チャットAPI </h4>
                <hr className='eduApiCardLine'/> 
                <ul className="eduApiSectionList">
                  <li>
                    <Image avatar src="/check.png" />
                    <List.Content>学習者モード</List.Content>
                  </li>
                  <li>
                    <Image avatar src="/check.png" />
                    <List.Content>チャットログAPI </List.Content>
                  </li>
                  <li>
                    <Image avatar src="/check.png" />
                    <List.Content>
                    分析API（学習者のチャット分析を含む）
                    </List.Content>
                  </li>
                  <li>
                    <Image avatar src="/check.png" />
                    <List.Content>イージーモード（Museフリートーク</List.Content>
                  </li>
                </ul>
            </div>



            <div className='eduApiSectionCard' style={{  width: isMobile ? "100%" :500, boxSizing:"border-box" ,height:isMobile ? "auto" : "450px" }}>
                <h4 className='eduApiCardHeader'>Corpus（コーパス）API</h4>
                <hr className='eduApiCardLine'/> 
                <ul className="eduApiSectionList">
                  <li>
                    <Image avatar src="/check.png" />
                    <List.Content>ユーザーリスト</List.Content>
                  </li>
                  <li>
                    <Image avatar src="/check.png" />
                    <List.Content>ツリーリスト</List.Content>
                  </li>
                  <li>
                    <Image avatar src="/check.png" />
                    <List.Content>
                    ユーザー進捗状況
                    </List.Content>
                  </li>
                  
                </ul>
            </div>


          </div>
        </div>{" "}
        <NextLevel />
      </>
    );
}
