import { createMedia } from "@artsy/fresnel";
import React, { useEffect } from "react";
import { Button, Divider, Dropdown, Image, Menu } from "semantic-ui-react";

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
});

export default function Topbar() {
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  useEffect(() => {
    console.log(window.location.pathname === '/solution/education/')
    console.log(window.location.pathname)
  }, [])

  return (
    <MediaContextProvider>
      <Media lessThan="md">
        <div
          style={{
            zIndex: 1002,
            backgroundColor: "white",
            left: 0,
            top: 0,
            position: "fixed",
            width: "100%",
            height: 60,
            backgroundColor:( window.location.pathname === '/jp/solution/education/' || window.location.pathname === '/jp/solution/enterprise/' || window.location.pathname === '/jp/solution/health/' || window.location.pathname === '/jp/solution/mobility/' ) ?  "#FFEEE4" : "#FFFFFF" 

          }}
        >
          {!open && (
            <Image
              style={{ zIndex: 1000, position: "fixed", left: 32, top: 9 }}
              src="/akalogo.png"
              href="https://www.akaintelligence.com/jp"
            />
          )}
          {open && (
            <Image
              onClick={() => {
                setOpen(!open);
              }}
              style={{ zIndex: 1002, position: "fixed", left: 32, top: 20 }}
              src="/menu_icons_close.png"
            />
          )}
          {!open && (
            <Image
              onClick={() => {
                setOpen(!open);
              }}
              style={{ zIndex: 1000, position: "fixed", right: 32, top: 18 }}
              width="26"
              src="/hamburger.png"
            />
          )}
        </div>
        {open && (
          <div
            style={{
              opacity: 0.95,
              zIndex: 1001,
              backgroundColor: "white",
              left: 0,
              top: 0,
              position: "fixed",
              width: "100%",
              height: "100vh",
              paddingTop: 112,
              paddingLeft: 32,
            }}
          >
            <div
              style={{ paddingRight: 20 }}
              onClick={() => {
                setOpen1(!open1);
              }}
            >
              <span style={{ fontSize: 15, float: "left" }}>
                <b>MUSE API</b>
              </span>
              {open1 ? (
                <img
                  style={{ float: "right" }}
                  src="/menu_icons_arrow_up.png"
                />
              ) : (
                <img
                  style={{ float: "right" }}
                  src="/menu_icons_arrow_down.png"
                />
              )}
            </div>
            <br />
            {open1 && (
              <div style={{ padding: 20 }}>
                <p style={{ paddingTop: 20 }}>
                  <a style={{ color: "grey", fontSize: 15 }} href="/jp/">
                    Muse API
                  </a>
                </p>
                <p style={{ paddingTop: 10 }}>
                  <a
                    style={{ color: "grey", fontSize: 15 }}
                    href="/jp/ai/analysis/"
                  >
                    Muse分析API
                  </a>
                </p>
                <p style={{ paddingTop: 10 }}>
                  <a style={{ color: "grey", fontSize: 15 }} href="/jp/ai/chat/">
                    MuseチャットAPI
                  </a>
                </p>
                <p style={{ paddingTop: 10 }}>
                  <a style={{ color: "grey", fontSize: 15 }} href="/jp/ai/vision/">
                    MuseチャットAPI
                  </a>
                </p>
              </div>
            )}
            <Divider hidden />
            <Divider hidden />

            <div
              style={{ paddingRight: 20 }}
              onClick={() => {
                setOpen2(!open2);
              }}
            >
              <span style={{ fontSize: 15, float: "left" }}>
                <b>活用分野</b>
              </span>
              {open2 ? (
                <img
                  style={{ float: "right" }}
                  src="/menu_icons_arrow_up.png"
                />
              ) : (
                <img
                  style={{ float: "right" }}
                  src="/menu_icons_arrow_down.png"
                />
              )}{" "}
            </div>
            <br />
            {open2 && (
              <div style={{ padding: 20 }}>
                <p style={{ paddingTop: 20 }}>
                  <a
                    style={{ color: "grey", fontSize: 15 }}
                    href="/jp/solution/education/"
                  >
                    教育
                  </a>
                </p>
                <p style={{ paddingTop: 10 }}>
                  <a
                    style={{ color: "grey", fontSize: 15 }}
                    href="/jp/solution/enterprise/"
                  >
                    ビジネス
                  </a>
                </p>
                <p style={{ paddingTop: 10 }}>
                  <a
                    style={{ color: "grey", fontSize: 15 }}
                    href="/jp/solution/health/"
                  >
                    ヘルスケア
                  </a>
                </p>
                <p style={{ paddingTop: 10 }}>
                  <a
                    style={{ color: "grey", fontSize: 15 }}
                    href="/jp/solution/mobility/"
                  >
                    モビリティ
                  </a>
                </p>
              </div>
            )}
            <Divider hidden />
            <Divider hidden />

            <span href="/pricing/" style={{ fontSize: 15, float: "left" }}>
              <b>ご利用料金</b>
            </span>
            <br />

            <Divider hidden />
            <Divider hidden />
            {/* <span style={{ fontSize: 15, float: "left" }}>
              <b style={{display:'flex'}}> AKA  <div style={{width:'10px'}}></div> ↗</b>
            </span> */}
            <br />
          </div>
        )}
        <div style={{ height: 60 }}></div>
      </Media>
      <Media greaterThanOrEqual="md">
        <Menu
          borderless
          style={{ padding: 20, backgroundColor:( window.location.pathname === '/jp/solution/education/' || window.location.pathname === '/jp/solution/enterprise/' || window.location.pathname === '/jp/solution/health/' || window.location.pathname === '/jp/solution/mobility/') ?  "#FFEEE4" : "#FFFFFF" }}
          fluid
          secondary
          fixed="top"
          stackable
        >
          <Menu.Item name="logo">
            <Image src="/akalogo.png" href="https://www.akaintelligence.com/jp" />
          </Menu.Item>
          <Dropdown item text="Muse API" style={{fontWeight: 700, fontSize:"1.25em"}}>
            <Dropdown.Menu
              style={{
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25);",
                borderTop: "solid #f37021 3px",
                borderRadius: 0,
              }}
            >
              <Dropdown.Item href="/jp/"><div style={{fontWeight: 700}}>MUSE API</div></Dropdown.Item>
              <Dropdown.Item href="/jp/ai/analysis/">
                <div style={{fontWeight: 700}}>Muse 分析 API</div>
              </Dropdown.Item>
              <Dropdown.Item href="/jp/ai/chat/"><div style={{fontWeight: 700}}>MuseチャットAPI</div></Dropdown.Item>
              <Dropdown.Item href="/jp/ai/vision/">
                <div style={{fontWeight: 700}}>MuseヴィジョンAPI</div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown item text="活用分野" style={{fontWeight: 700 , fontSize:"1.25em"}}>
            <Dropdown.Menu
              style={{
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25);",
                borderTop: "solid #f37021 3px",
                borderRadius: 0,
              }}
            >
              <Dropdown.Item href="/jp/solution/education/">
                <div style={{fontWeight: 700}}>教育</div>
              </Dropdown.Item>
              <Dropdown.Item href="/jp/solution/enterprise/">
                <div style={{fontWeight: 700}}>ビジネス</div>
              </Dropdown.Item>
              <Dropdown.Item href="/jp/solution/health/">
                <div style={{fontWeight: 700}}>ヘルスケア</div>
              </Dropdown.Item>
              <Dropdown.Item href="/jp/solution/mobility/"><div style={{fontWeight: 700}}>モビリティ</div></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Menu.Item name="Muse API" href="/jp/pricing/" style={{fontWeight: 700, fontSize:"1.25em"}}>
            ご利用料金
          </Menu.Item>
          {/* <Menu.Item name="Muse API" href="https://www.akaintelligence.com/">
            AKA
          </Menu.Item> */}

          <Menu.Item position="right" name="maps">
            <Button color="orange" href="/sales/" style={{fontSize:"1.25em"}}>
              お問い合わせ
            </Button>
          </Menu.Item>
        </Menu>
        <div style={{ height: 110 }}></div>
      </Media>
    </MediaContextProvider>
  );
}
