import { createMedia } from "@artsy/fresnel";
import React, { Component } from "react";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  Segment,
} from "semantic-ui-react";
import NextLevel from "../components/NextLevel";
import SolutionAccordion from "../components/SolutionAccordion";
import "../styles/queries.css";
import "../assets/mobile/Home.css"
import { useMediaQuery } from 'react-responsive'

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
});

export function Home_kr() {
  const isMobile = useMediaQuery({query: '(max-width: 768px)'})

  return (<>

        <Container>
          <Grid container stackable verticalAlign="middle">
            <Grid.Row columns={2}>
              <Grid.Column width={7}>
                <div className="mobileAiImageCont" style={{marginTop: "3em"}}> 
                  <Image src="/muse.png" />
                </div>
                <h1 className='mainTopHeader'>AI Communication Engine</h1> 
                {/* <Divider hidden /> */}
                <p className="mainTopContent">
                  뮤즈는 딥러닝, 빅데이터, NLP 기술과 머신 비전을 사용하여 교육, 헬스케어, 농업 등의 분야에서 필요로 하는 역량을 제공합니다.
                </p>
                {
                !isMobile &&
                  <>
                  <Divider hidden />{" "}
                  <Divider hidden />{" "}
                  <Divider hidden />{" "}
                  </>
                }
                {
                isMobile &&
                <Grid.Column width={9}>
                  <Image src="/homepagelogo.png" width='310px' style={{margin:"auto"}}/>
                </Grid.Column>
                }

                <div className='mainButtonContTop'>
                <Button  href="/sales/" color="orange" className="huge ui button" >
                  구매 상담
                </Button>
                {/* <Button
                  href="https://apps.apple.com/kr/app/april%EB%B9%84%EB%82%98%ED%86%A1/id1486317712"
                  inverted
                  color="orange"
                  className="huge ui button"
                >
                  Start a free trial
                </Button> */}

                </div>
               
              </Grid.Column>
              {!isMobile &&
                <Grid.Column width={9}>
                  <Image src="/homepagelogo.png"/>
                </Grid.Column>
              }
              
            </Grid.Row>
          </Grid>
        </Container>
        {
          !isMobile &&
            <>
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            </>
        }

        { isMobile &&<Divider hidden />}


        <Grid
          celled="internally"
          columns="equal"
          stackable
          style={{ padding:isMobile ? "1em 0em" :  "4em 0em", backgroundImage: "url(/watchvid.png)" }}
        >
          <Grid.Row textAlign="center">
            <Grid.Column style={{ paddingBottom: "5em", paddingTop: "5em" }}>
              <Header as="h3" style={{ fontSize: "2em" }}>
              <p className="mobileMainTextWidth">
                MUSE API는 인간 수준에 가까운 대화를 가능케 합니다.
              </p>
              </Header>
              <Divider hidden />
              <Divider hidden />
              <Divider hidden />
              {/* <a className="mobileLinkMainWatchVideo" href="#" style={{ color: "#F2711C" }}>Watch a video <Icon name="arrow right" /></a> */}
              <p className="mobileSubTextWidth" style={{color:"#F2711C"}}>Stand Out From The Competition With Lifelike AI Cognition</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {
          !isMobile &&
            <>
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            </>
        }

      {
          isMobile &&
            <>
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            </>
        }

        <Grid container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header as="h3" style={{color:"#6B7480"}}>MUSE API 01</Header>
              <Header as="h1">MUSE AI 분석 API</Header>
              <p className="changeTextWidth">
              뮤즈 분석은 사용자의 대화를 분석해줍니다. 문장의 구조 및 패턴을 인식하여 사용자의 발화 내용을 분석하며, 문장, 단어, 발음과 대화 맥락까지 측정합니다.{" "}
              </p>
              <a className="mobileLinkMainWatchVideo" href="/kr/ai/analysis/" style={{ color: "#F2711C" }}>더 알아보기 <Icon name="arrow right" /></a>
            </Grid.Column>
            { !isMobile &&
              <Grid.Column>
              <Image src="/introanalysis.png"/>
              </Grid.Column>
            }
          </Grid.Row>
        </Grid>

        { !isMobile &&
        <div>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        </div>
        }

      { isMobile &&
        <div>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        </div>
        }
        

        <Grid className='mobileVersionMuseApi' container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            { !isMobile &&
              <Grid.Column>
                <Image src="/mobilechat.png"/>
              </Grid.Column>
            }
            <Grid.Column>
              <Header as="h3" style={{color:"#6B7480"}}>MUSE API 02</Header>
              <Header as="h1">MUSE AI 챗 API</Header>
              <p className="changeTextWidth">
              MUSE 대화는 음성인식과 음성 합성 기술을 통해 발화를 문자로, 문자를 발화로 전환시킵니다. 교과서와 교육용 컨첸츠를 활용해 챗봇을 제공하고 응답을 제시하며 영어 수업을 진행합니다.{" "}
              </p>
              <a  className='mobileLinkMainWatchVideo' href="/kr/ai/chat/" style={{ color: "#F2711C" }}>더 알아보기<Icon name="arrow right" /></a>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        { !isMobile &&
        <div>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        </div>
        }

      { isMobile &&
        <div>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        </div>
        }
        
        
        <Grid className='mobileVersionMuseApi' container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header as="h3" style={{color:"#6B7480"}}>MUSE API 03</Header>
              <Header as="h1">Muse AI 비전 API</Header>
              <p className="changeTextWidth">
                뮤즈 비전은 사용자와 사용자의 얼굴 표정을 시각적으로 인식합니다. 또한 사물을 식별하여 식물과 과일을 구별하고 식물의 상태와 수확 적합 여부를 판단합니다.{" "}
              </p>

              <a className='mobileLinkMainWatchVideo' href="/kr/ai/vision/" style={{ color: "#F2711C" }}>더 알아보기 <Icon name="arrow right" /></a>

            </Grid.Column>
            { !isMobile &&
            <Grid.Column>
              <Image src="/homevision.png"/>
            </Grid.Column>
            }
          </Grid.Row>
        </Grid>

        { !isMobile &&
        <div>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        </div>
        }

      { isMobile &&
        <div>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        </div>
        }
        

        <Header as="h1" textAlign="center" style={{ fontSize: "3em" }}>
          활용 분야
        </Header>

        { !isMobile &&
        <div>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        </div>
        }

        <SolutionAccordion isMobile={isMobile} />

        <Divider hidden />
        <Divider hidden />




        <Segment
          style={{
            padding: isMobile ? "6em 1em" : "8em",
            border: 0,
            backgroundColor: "#f7f7f8",
          }}
          vertical
        >
          <Grid container stackable verticalAlign="middle">
            <Grid.Row columns={2}>
              <Grid.Column>
                <Header style={{fontSize: "15px", margin:"0px", color: "#6B7480"}} as="h3">Muse API 성공 사례</Header>
                <Header as="h1">청담 러닝: Vena Talk</Header>
                <p style={{fontSize:"24px"}}>
                  VENA TALK는 어린이들을 위한 무제한 프리챗 기능이 있는 뮤즈 기반 영어 교육 어플입니다. 세 단계의 과정(1. 연습, 2. 역할놀이, 3. 프리토킹)을 통해 학생들은 표현과 스피킹 패턴을 학습하고 자유롭게 대화할 수 있습니다.
                </p>

              {!isMobile &&<a
                href="https://apps.apple.com/kr/app/april%EB%B9%84%EB%82%98%ED%86%A1/id1486317712"
                style={{ color: "#F2711C", fontSize:"24px" }}
              >
                구경하기 <Icon name="arrow right" />
              </a>}
              {isMobile &&
                <div>
              <Divider hidden />
              <Divider hidden />
              <Divider hidden />
              </div>}

              {isMobile &&<a className="mobileLinkMainWatchVideo" href="#" style={{ color: "#F2711C" }}>Watch a video <Icon name="arrow right" /></a>}


              </Grid.Column>
              {!isMobile && <Grid.Column>
                <Image src="/edu5.png"/>
                <Image src="/edu4.png"/>
              </Grid.Column>}
            </Grid.Row>
          </Grid>

          {!isMobile &&
          <Grid stackable style={{ paddingTop: isMobile ? "1em" : "8em" }} centered columns={2}>
            <Grid.Row columns={3}>
              <Grid.Column textAlign="center">
                <Image width={isMobile ? "83px" : 'auto'} src="/logo_softbank.png" />
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Image  width={isMobile ? "83px" : 'auto'} src="/logo_kddi.png" />
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Image  width={isMobile ? "61px" : 'auto'} src="/logo_speecys.png" />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={3}>
              <Grid.Column>
                <Image   width={isMobile ? "109px" : 'auto'}  src="/logo_rakuten.png" />
              </Grid.Column>
              <Grid.Column>
                <Image  width={isMobile ? "94px" : 'auto'}  src="/logo_kanji.png" />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          }
          {isMobile &&
          <div style={{display:"flex" , marginTop:"46px", flexWrap:"wrap" , columnGap:"38px", justifyContent:"center" , rowGap:"35px"}}>
                <Image width={isMobile ? "83px" : 'auto'} src="/logo_softbank.png" />
                <Image  width={isMobile ? "83px" : 'auto'} src="/logo_kddi.png" />
                <Image  width={isMobile ? "61px" : 'auto'} src="/logo_speecys.png" />
                <Image   width={isMobile ? "109px" : 'auto'}  src="/logo_rakuten.png" />
                <Image  width={isMobile ? "94px" : 'auto'}  src="/logo_kanji.png" />
          </div>
          }
        </Segment>


          {!isMobile &&

            <div>
              <Divider hidden />
              <Divider hidden />
              <Divider hidden />
              <Divider hidden />
              <Divider hidden/>
              <Divider hidden/>
              <Divider hidden/>
              <Divider hidden/>
            </div>
          }
        

        <NextLevel />
      </>
  );
}



