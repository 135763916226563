import { createMedia } from "@artsy/fresnel";
import React, { Component } from "react";
import {
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  Segment,
} from "semantic-ui-react";
import NextLevel from "../components/NextLevel";
import { useMediaQuery } from 'react-responsive'

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
});
export function SolutionEnterprise_jp() {
  const isMobile = useMediaQuery({query: '(max-width: 768px)'})

    return (
      <>
        <Segment
          style={{
            border: 0,
            padding: "0em 0em 0em 0em",
            backgroundColor: "#ffeee4",
          }}
          vertical
        >
          {!isMobile &&
            <Grid container stackable verticalAlign="middle">
              <Grid.Row style={{ height: "500px" }} columns={1}>
                <Grid.Column width={8}>
                  <Header style={{ fontSize: '28px', color: '#F37021', marginTop:"50px" }}>ビジネス </Header>
                  <Header style={{ fontSize: '48px' }}>
                  企業のための人間の労働者を補う
                  </Header>
                  <Button style={{marginTop:'40px'}} color="orange" className="huge ui button" href="/sales/">お問い合わせ</Button>
                </Grid.Column>
                <Grid.Column style={{ marginLeft: "200px", marginTop:"-300px"}}>
                  <Image src="/enterprise1.png" style={{width:'1000px', height:'536px', marginTop:'50px'}} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          }
           {isMobile &&
            <Grid container stackable verticalAlign="middle">
            <Grid.Row columns={2}>
              <Grid.Column style={{textAlign:"center"}}>
                <Header as="h3" style={{marginTop:"15px", textAlign:"center"}}>ビジネス</Header>
                <Header style={{ fontSize: '28px' }}>
                企業のための人間の労働者を補う
                </Header>
                <Image style={{width:"311px", margin:"auto"}} src="/enterprise_mobile.png" />
                <Button style={{marginTop:'40px'}} color="orange" className="huge ui button" href="/sales/">お問い合わせ</Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          }
        </Segment>

        <div className='solution-divider'></div>

        

        <Header as="h1" textAlign="center" style={{ fontSize: "3em" }}>
        ビジネス
        </Header>

        <Divider hidden />

        <Grid container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            {isMobile &&<Grid.Column>
              <Image style={{width:isMobile ? "311px" : "auto" , margin:"auto"}} src="/enterprise2.png" />
            </Grid.Column>}



            <Grid.Column>
              <Header as="h3">ビジネス</Header>
              <Header as="h1">Muse J X Ken Robotech</Header>
              <p style={{fontSize:"24px"}}>
              建ロボテック株式会社は、AKAと建設現場の生産性を向上させる音声通信機能を搭載した作業ロボットの共同開発契約を締結しました。{" "}
              </p>
              <p style={{fontSize:"24px"}}>
              建ロボテック株式会社によって開発および販売され、労働者の負担を軽減します。
              </p>

              {
                !isMobile &&
                <a href="#" style={{ color: "#F2711C", fontSize:"24px" }}>
                詳しくはこちら <Icon name="arrow right" />
              </a>}

              {isMobile &&   <a  className='mobileLinkMainWatchVideo' href="#" style={{ color: "#F2711C" }}>詳しくはこちら <Icon name="arrow right" /></a>}

              

            </Grid.Column>
            {!isMobile &&
            <Grid.Column>
              <Image src="/enterprise2.png" />
            </Grid.Column>
            }
          </Grid.Row>
        </Grid>

        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />

        <Grid container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            {!isMobile &&
            <Grid.Column>
              <Image src="/enterprise3.png" />
            </Grid.Column>
            }
            {
              isMobile &&
              <Grid.Column>
                <Image style={{width:isMobile ? "311px" : "auto" , margin:"auto"}} src="/enterprise3.png" />
              </Grid.Column>
            }

            <Grid.Column>
              <Header as="h3" style={{marginTop:"40px"}}>ビジネス</Header>
              <Header as="h1">Muse J X VAIO</Header>
              <p style={{fontSize:"24px"}}>
              日本語のMuse NLPエンジンは「VAIO おしゃべりコウペンちゃん」に搭載されています。
              このロボットは人気アニメキャラクターをベースにしており、「かんたんモード」とクラウドサービスを利用して遊ぶ「いっぱいモード」があります。
              このソリューションには会話認識機能が搭載されており、最先端の合成音声機能も搭載されています。
              </p>
              

              {!isMobile &&  <a href="https://musioenglish.kr/" style={{ color: "#F2711C" ,fontSize:"24px" }}>
                웹ウェブサイトに行く <Icon name="arrow right" />
              </a>}
              {isMobile &&   <a  className='mobileLinkMainWatchVideo' href="/ai/chat/" style={{ color: "#F2711C" }}>ウェブサイトに行く <Icon name="arrow right" /></a>}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />

        <NextLevel />
      </>
    );
}
