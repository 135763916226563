import { createMedia } from "@artsy/fresnel";
import React, { Component } from "react";
import {
  Button,
  Divider,
  Grid,
  Header,
  Image,
  Segment,
} from "semantic-ui-react";
import NextLevel from "../components/NextLevel";
import { useMediaQuery } from 'react-responsive'

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
});
export function SolutionMobility() {

  const isMobile = useMediaQuery({query: '(max-width: 768px)'})

    return (
      <>
        <Segment
          style={{
            border: 0,
            padding: "0em 0em 0em 0em",
            backgroundColor: "#ffeee4",
          }}
          vertical
        >
          {!isMobile && 
            <Grid container stackable verticalAlign="middle">
            <Grid.Row style={{ height: "500px" }} columns={1}>
              <Grid.Column width={8}>
                <Header style={{ fontSize: '28px', color: '#F37021', marginTop:"50px"}}>MOBILITY </Header>
                <Header style={{ fontSize: '48px' }}>
                  Supplement the Human Driver
                </Header>
                <Button style={{marginTop:'40px'}} color="orange" className="huge ui button" href="/sales/">Talk to Sales</Button>
              </Grid.Column>
              <Grid.Column style={{ marginLeft: "200px", marginTop:"-300px"}}>
                <Image src="/mobility1.png" style={{width:'1000px', height:'536px'}}/>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          }

          {isMobile && 
            <Grid container stackable verticalAlign="middle">
              <Grid.Row columns={2}>
                <Grid.Column style={{textAlign:"center"}}>
                  <Header as="h3" style={{marginTop:"15px", textAlign:"center"}}>MOBILITY  </Header>
                  <Header style={{ fontSize: '28px' }}>
                  Supplement the Human Driver
                  </Header>
                  <Image style={{width:"311px", margin:"auto"}} src="/mobility-mobile.png" />
                  <Button style={{marginTop:'40px'}} color="orange" className="huge ui button" href="/sales/">Talk to Sales</Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          }
          
        </Segment>

        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />

        <Header as="h1" className='pageTopParth1' textAlign="center" style={{ marginTop:"100px" }}>
          Mobility{" "}
        </Header>

        <Divider hidden />

        <Grid container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header as="h3" style={{marginTop:"40px"}}>Mobility</Header>
              <Header as="h1">Driving Companion</Header>
              <p style={{fontSize:"24px"}}>As a memory-based conversation generator that learns through experience, Muse enables natural, intuitive communication between humans and their automobiles.
                Muse not only communicates with the driver and delivers its own opinions, it also recognizes and empathizes with the driver's emotions.{" "}
              </p>
            </Grid.Column>
            <Grid.Column>
              <Image src="/mobility2.png" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Divider hidden />

        <Divider hidden />
        <Divider hidden />

        <Grid container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Image src="/mobility3.png" />
            </Grid.Column>

            <Grid.Column>
              <Header as="h3" style={{marginTop:"40px"}}>Mobility</Header>
              <Header as="h1">MUSE Memory Structure</Header>
              <p style={{fontSize:"24px"}}>Muse is a memory-based conversation generator that analyzes user-spoken sentences and extracts semantically significant elements from them. Muse will remember specific words, details, facts, and even images, and will reference them in future conversations.</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Divider hidden />

        <Divider hidden />
        <Divider hidden />

        <NextLevel />
      </>
    );
  
}
