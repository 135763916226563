import { color } from "echarts";
import React from "react";
import { Container, Grid, Header, List, Segment, Accordion, Icon } from "semantic-ui-react";

export default function Footer_kr() {
  const [open, setOpen] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState();

  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index

    setActiveIndex(newIndex);
  }

  return (
    <Segment vertical style={{ padding: "5em 0em" }} inverted>
        <div style={{ paddingLeft: "32px" }}>
        <Accordion inverted className="hide-in-desktop">
            <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={handleClick}
            >
                <Icon name='dropdown' />
                商品
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
            <div style={{ paddingLeft: "20px" }}>
                <List link inverted>
                    <List.Item as="a" href="/jp/">
                        Muse API
                    </List.Item>
                    <List.Item as="a" href="/jp/ai/analysis/">
                        Muse分析API
                    </List.Item>
                    <List.Item as="a" href="/jp/ai/chat/">
                        MuseチャットAPI
                    </List.Item>
                    <List.Item as="a" href="/jp/ai/vision/">
                        MuseヴィジョンAPI
                    </List.Item>
                </List>
            </div>
            </Accordion.Content>

            <Accordion.Title
                active={activeIndex === 1}
                index={1}
                onClick={handleClick}
            >
            <Icon name='dropdown' />
                ソリューション
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
            <div style={{ paddingLeft: "20px" }}>
                <List link inverted>
                    <List.Item as="a" href="/jp/solution/education">
                        教育
                    </List.Item>
                    <List.Item as="a" href="/jp/solution/enterprise">
                        ビジネス
                    </List.Item>
                    <List.Item as="a" href="/jp/solution/health">
                        ヘルスケア
                    </List.Item>
                    <List.Item as="a" href="/jp/solution/mobility">
                        モビリティ
                    </List.Item>
                </List>
            </div>
            </Accordion.Content>

            <Accordion.Title
                active={activeIndex === 2}
                index={2}
                onClick={handleClick}
            >
            <Icon name='dropdown' />
                ご利用料金
            </Accordion.Title>
            <Accordion.Content>
            <div style={{ paddingLeft: "20px" }}>
                {/* Here will be Pricing Content */}
            </div>
            </Accordion.Content>

            <Accordion.Title
                active={activeIndex === 3}
                index={3}
                onClick={handleClick}
            >
            <Icon name='dropdown' />
                企業概要
            </Accordion.Title>
            <Accordion.Content>
            <div style={{ paddingLeft: "20px" }}>
                {/* Here will be Company Content */}
            </div>
            </Accordion.Content>
        </Accordion>
        </div>
      <Container className="hide-in-mobile">
        <Grid stackable>
          <Grid.Row columns={4}>
            <Grid.Column>
              <Header as="a" content="商品" inverted />
              <List link inverted>
                <List.Item as="a" href="/jp/">
                  Muse API
                </List.Item>
                <List.Item as="a" href="/jp/ai/analysis/">
                  Muse分析API
                </List.Item>
                <List.Item as="a" href="/jp/ai/chat/">
                  MuseチャットAPI
                </List.Item>
                <List.Item as="a" href="/jp/ai/vision/">
                  MuseヴィジョンAPI
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <Header as="a" content="ソリューション" inverted />
              <List link inverted>
                <List.Item as="a" href="/jp/solution/education">
                  教育
                </List.Item>
                <List.Item as="a" href="/jp/solution/enterprise">
                  ビジネス
                </List.Item>
                <List.Item as="a" href="/jp/solution/health">
                  ヘルスケア
                </List.Item>
                <List.Item as="a" href="/jp/solution/mobility">
                  モビリティ
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <Header as="a" href="/jp/pricing" content="モビリティ" inverted />
            </Grid.Column>
            <Grid.Column>
              <Header
                as="a"
                href="https://www.akaintelligence.com/jp"
                content="企業概要"
                inverted
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
}
