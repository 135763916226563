import { createMedia } from "@artsy/fresnel";
import React, { Component } from "react";
import {
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Segment,
} from "semantic-ui-react";
import NextLevel from "../components/NextLevel";
import "../assets/global/components/education.css"
import { useMediaQuery } from 'react-responsive'

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
});
export function SolutionEducation_kr() {

  const isMobile = useMediaQuery({query: '(max-width: 768px)'})

  return (
      <>
        <Segment
          style={{
            border: 0,
            padding: "0em 0em 0em 0em",
            backgroundColor: "#ffeee4",
          }}
          vertical
        >
          {!isMobile &&
            <Grid container stackable verticalAlign="middle">
              <Grid.Row style={{ height: "500px" }} columns={1}>
                <Grid.Column width={8}>
                  <Header style={{ fontSize: '28px', color: '#F37021', marginTop:"50px" }}>교육 </Header>
                  <Header style={{ fontSize: '48px' }}>
                    원어민 영어 강사 대체
                  </Header>
                  <Button style={{marginTop:'40px'}} color="orange" className="huge ui button" href="/sales/">구매 상담</Button>
                </Grid.Column>
                <Grid.Column style={{ marginLeft: "200px", marginTop:"-300px"}}>
                  <Image src="/edu-img.png" style={{width:'1000px', height:'536px', marginTop:'50px'}}/>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            }
            {isMobile &&
              <Grid container stackable verticalAlign="middle">
              <Grid.Row columns={2}>
                <Grid.Column style={{textAlign:"center"}}>
                  <Header as="h3" style={{marginTop:"15px", textAlign:"center"}}>교육 </Header>
                  <Header style={{ fontSize: '28px' }}>
                    원어민 영어 강사 대체
                  </Header>
                  <Image style={{width:"311px", margin:"auto"}} src="/edu_img_mobile.png" />
                  <Button style={{marginTop:'40px'}} color="orange" className="huge ui button" href="/sales/">구매 상담</Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            }
        </Segment>

        <div className='solution-divider'></div>

        <h1 className='pageTopParth1' style={{textAlign:"center"}}>
          교육
        </h1>

        <Divider hidden />

        <Grid container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Image style={{width:isMobile ? "311px" : "auto" , margin:"auto"}} src="/edugirl.png" />
            </Grid.Column>

            <Grid.Column>
              <Header as="h3" style={{marginTop:"40px"}}>교육</Header>
              <Header as="h1">Muse 교육용 API</Header>
              <p style={{fontSize:"24px"}}>
                Muse의 통합적 의사소통 능력으로 학생들의 참여를 유도합니다. Muse는 문자, 구어, 사람의 몸짓과 표정까지 이해하는 완벽한 원어민 영어 교사입니다.
              </p>
              <List bulleted style={{fontSize:"24px"}}>
                <List.Item>
                  Muse API X CDL
                  <List.List>
                    <List.Item>Vena Talk</List.Item>
                    <List.Item>전환율이 무려 96% 이상</List.Item>
                  </List.List>
                </List.Item>
                <List.Item>
                  Muse API X Softbank Robotics China
                  <List.List>
                    <List.Item>페퍼</List.Item>
                    <List.Item>페퍼 2,000대 이상</List.Item>
                  </List.List>
                </List.Item>
                <List.Item>Muse X 옥스포드 대학출판부</List.Item>
              </List>
              {
                !isMobile &&
                <a href="https://launcher.akaai.io/kr" style={{ color: "#F2711C", fontSize:"24px" }}>
                더 알아보기 <Icon name="arrow right" />
              </a>}

              {isMobile && <Divider hidden />}
              {isMobile && <Divider hidden />}
              {isMobile &&   <a  className='mobileLinkMainWatchVideo' href="/ai/chat/" style={{ color: "#F2711C" }}>Go to the Website <Icon name="arrow right" /></a>}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />

        {isMobile &&
            <Grid.Column>
              <Image style={{width:isMobile ? "311px" : "auto" , margin:"auto"}} src="/edu2.png" />
          </Grid.Column>}
          {isMobile && <Divider hidden />}
          {isMobile && <Divider hidden />}
        <Grid container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header as="h3">교육</Header>
              <Header as="h1">Musio 잉글리시</Header>
              <p style={{fontSize:"24px"}}>
                뮤지오는 공립학교에서 유일하게 인증된 인공지능 로봇입니다. 한국과 일본의 210개가 넘는 교육기관에서 뮤즈를 탑재한 뮤지오를 도입했으며, 뮤지오 사용자의 70% 이상이 영어 사용이 늘고 영어에 대한 자신감이 생겼습니다.
              </p>
              <List bulleted style={{fontSize:"24px"}}>
                <List.Item>Muse API X 캠브리지 대학교 출판부</List.Item>
                <List.Item>
                  Muse API X Online Video English
                  <List.List>
                    <List.Item>Musio X Kimini (Gakken)</List.Item>
                    <List.Item>전환율 64.2%</List.Item>
                  </List.List>
                </List.Item>
              </List>
              {isMobile && <Divider hidden />}
              {isMobile && <Divider hidden />}
              {!isMobile &&  <a href="https://musioenglish.kr/" style={{ color: "#F2711C" ,fontSize:"24px"}}>
                웹사이트로 이동 <Icon name="arrow right" />
              </a>}
              {isMobile &&   <a  className='mobileLinkMainWatchVideo' href="/ai/chat/" style={{ color: "#F2711C" }}>웹사이트로 이동 <Icon name="arrow right" /></a>}

            </Grid.Column>

            {!isMobile &&
            <Grid.Column>
              <Image style={{width:isMobile ? "311px" : "auto" , margin:"auto"}} src="/edu2.png" />
            </Grid.Column>}
          </Grid.Row>
        </Grid>

        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />

        <Grid container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Image style={{width:isMobile ? "311px" : "auto" , margin:"auto"}} src="/edu3.png" />
            </Grid.Column>

            <Grid.Column>
              <Header as="h3">교육</Header>
              <Header as="h1">출판 컨텐츠 & 파트너십</Header>
              <List bulleted style={{width:"100%", fontSize:"24px", lineHeight:"150%" }}>
                <List.Item>
                  아카는 교육 컨텐츠를 디지털화시켜 AI가 수업을 진행하게 만들 수 있습니다. -180개의 교과서
                </List.Item>
                <List.Item>
                  국내 정상급 교육기업 해커스 교육그룹 공동 창립자의 주도 하에 자체 제작한 최고의 교육용 컨텐츠를 제공
                </List.Item>
                <List.Item>파트너십 목표 & 성과</List.Item>
                <List.Item>
                  베스트셀링 교육 기업과 파트너십 체결(국내 시장)
                </List.Item>
                <List.Item>출판사와 컨텐츠 개발</List.Item>
                <List.Item>타겟 유저를 위해 다양한 레벨로 개발</List.Item>
              </List>
              {isMobile && <Divider hidden />}
              {isMobile && <Divider hidden />}
              {!isMobile &&  <a href="#" style={{ color: "#F2711C", fontSize:"24px" }}>더 알아보기 <Icon name="arrow right" /></a>}
              {isMobile &&   <a  className='mobileLinkMainWatchVideo' href="/ai/chat/" style={{ color: "#F2711C" }}>Go to the Website <Icon name="arrow right" /></a>}

            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div>
        <Divider hidden />
        <Divider hidden />

        <Divider hidden />
        <Divider hidden />
        </div>
        <Segment
          style={{ padding: isMobile ? "3em 0em" : "8em 0em", border: 0, backgroundColor: "#ffeee4" }}
          vertical
        >

          <Grid container stackable verticalAlign="middle">
            <Grid.Row columns={2}>
              {isMobile &&
                <Grid.Column>
                <Image style={{width:isMobile ? "103px" :"auto", float:'right'}} src="/edu5.png" />
                <Image  src="/edu4.png" />
              </Grid.Column>}

              <Grid.Column>
                <Header as="h3">Muse API 성공 사례</Header>
                <Header as="h1">청담 러닝 Vena Talk</Header>
                <p style={{fontSize: '24px'}}>
                  VENA TALK는 어린이들을 위한 무제한 프리챗 기능이 있는 뮤즈 기반 영어 교육 어플입니다. 세 단계의 과정(1. 연습, 2. 역할놀이, 3. 프리토킹)을 통해 학생들은 표현과 스피킹 패턴을 학습하고 자유롭게 대화할 수 있습니다.
                </p>
                

                {!isMobile &&  <a href="https://apps.apple.com/kr/app/april%EB%B9%84%EB%82%98%ED%86%A1/id1486317712" style={{ color: "#F2711C", fontSize:"24px" }}>
                  구경하기 <Icon name="arrow right" />
                </a>}
                {isMobile && <Divider hidden />}
                {isMobile && <Divider hidden />}
              {isMobile &&   <a  className='mobileLinkMainWatchVideo' href="/ai/chat/" style={{ color: "#F2711C" }}>Watch a video <Icon name="arrow right" /></a>}

              </Grid.Column>
                {!isMobile &&
                <Grid.Column>
                <Image src="/edu5.png" />
                <Image src="/edu4.png" />
              </Grid.Column>}
            </Grid.Row>

            {isMobile &&
            <div style={{width:"100%", display:"flex" , margin:"auto", justifyContent:"space-between"}} >
                <Image width="76px" src="/icon1.png" />
                <Image width="110px" src="/icon2.png" />
                <Image  width="75px"src="/icon3.png" />
            </div>
            }
            {!isMobile &&
            <Grid.Row columns={3}>
              <Grid.Column>
                <Image src="/icon1.png" />
              </Grid.Column>
              <Grid.Column>
                <Image src="/icon2.png" />
              </Grid.Column>
              <Grid.Column>
                <Image src="/icon3.png" />
              </Grid.Column>
            </Grid.Row>
            }
          </Grid>
        </Segment>
        <NextLevel />
      </>
    )
}
