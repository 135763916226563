import { createMedia } from "@artsy/fresnel";
import React, { Component } from "react";
import {
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  Segment,
} from "semantic-ui-react";
import NextLevel from "../components/NextLevel";
import { useMediaQuery } from 'react-responsive'

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
});
export function SolutionEnterprise() {
  const isMobile = useMediaQuery({query: '(max-width: 768px)'})

    return (
      <>
        <Segment
          style={{
            border: 0,
            padding: "0em 0em 0em 0em",
            backgroundColor: "#ffeee4",
          }}
          vertical
        >
          {!isMobile &&
            <Grid container stackable verticalAlign="middle">
            <Grid.Row style={{ height: "500px" }} columns={1}>
              <Grid.Column width={8}>
                <Header style={{ fontSize: '28px', color: '#F37021', marginTop:"50px" }}>ENTERPRISE </Header>
                <Header style={{ fontSize: '48px' }}>
                  Supplement the Human Worker for Enterprise
                </Header>
                <Button style={{marginTop:'40px'}} color="orange" className="huge ui button" href="/sales/">Talk to Sales</Button>
              </Grid.Column>
              <Grid.Column style={{ marginLeft: "200px", marginTop:"-300px"}}>
                <Image src="/enterprise1.png" style={{width:'1000px', height:'536px'}}/>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          }
          {isMobile &&
            <Grid container stackable verticalAlign="middle">
            <Grid.Row columns={2}>
              <Grid.Column style={{textAlign:"center"}}>
                <Header as="h3" style={{marginTop:"15px", textAlign:"center"}}>ENTERPRISE </Header>
                <Header style={{ fontSize: '28px' }}>
                Supplement the Human Worker for Enterprise
                </Header>
                <Image style={{width:"311px", margin:"auto"}} src="/enterprise_mobile.png" />
                <Button style={{marginTop:'40px'}} color="orange" className="huge ui button" href="/sales/">Talk to Sales</Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          }
        </Segment>

        <div className='solution-divider'></div>

        

        <Header as="h1" textAlign="center" style={{ fontSize: "3em" }}>
          Enterprise
        </Header>

        <Divider hidden />

        <Grid container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            {isMobile &&<Grid.Column>
              <Image style={{width:isMobile ? "311px" : "auto" , margin:"auto"}} src="/enterprise2.png" />
            </Grid.Column>}



            <Grid.Column>
              <Header as="h3">Enterprise</Header>
              <Header as="h1">Muse J X Ken Robotech</Header>
              <p style={{fontSize:"24px"}}>
                AKA and Ken Robotech announced a joint development contract to build robots with voice communication, meant to improve construction site productivity and reduce the burden on workers.{" "}
              </p>
              <p style={{fontSize:"24px"}}>
                Developed and sold by Ken Robotech and reduce the burden on
                workers.
              </p>

              {
                !isMobile &&
                <a href="#" style={{ color: "#F2711C" , fontSize:"24px" }}>
                Learn more <Icon name="arrow right" />
              </a>}

              {isMobile &&   <a  className='mobileLinkMainWatchVideo' href="#" style={{ color: "#F2711C" }}>Learn More <Icon name="arrow right" /></a>}

              

            </Grid.Column>
            {!isMobile &&
            <Grid.Column>
              <Image src="/enterprise2.png" />
            </Grid.Column>
            }
          </Grid.Row>
        </Grid>

        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />

        <Grid container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            {!isMobile &&
            <Grid.Column>
              <Image src="/enterprise3.png" />
            </Grid.Column>
            }
            {
              isMobile &&
              <Grid.Column>
                <Image style={{width:isMobile ? "311px" : "auto" , margin:"auto"}} src="/enterprise3.png" />
              </Grid.Column>
            }

            <Grid.Column>
              <Header as="h3" style={{marginTop:"40px"}}>ENTERPRISE</Header>
              <Header as="h1">Muse J X VAIO</Header>
              <p style={{fontSize:"24px"}}>
              Muse NLP Engine for Japanese is embedded in 'VAIO Talking Koupen-chan'
              This robot is based on a highly popular animated character, in a stand-alone version and a cloud-based version. 
              The solution has built-in speech recognition, and state-of-the-art synthetic speech capability.
              </p>
              

              {!isMobile &&  <a href="https://musioenglish.kr/" style={{ color: "#F2711C" ,fontSize:"24px" }}>
                Go to the website <Icon name="arrow right" />
              </a>}
              {isMobile &&   <a  className='mobileLinkMainWatchVideo' href="/ai/chat/" style={{ color: "#F2711C" }}>Go to the Website <Icon name="arrow right" /></a>}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />

        <NextLevel />
      </>
    );
}
