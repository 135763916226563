import { createMedia } from "@artsy/fresnel";
import React, { Component } from "react";
import {
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Segment,
} from "semantic-ui-react";
import NextLevel from "../components/NextLevel";
import "../assets/global/components/education.css"
import { useMediaQuery } from 'react-responsive'

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
});
export function SolutionEducation_cn() {

  const isMobile = useMediaQuery({query: '(max-width: 768px)'})

  return (
      <>
        <Segment
          style={{
            border: 0,
            padding: "0em 0em 0em 0em",
            backgroundColor: "#ffeee4",
          }}
          vertical
        >
          {!isMobile &&
            <Grid container stackable verticalAlign="middle">
              <Grid.Row style={{ height: "500px" }} columns={1}>
                <Grid.Column width={8}>
                  <Header style={{ fontSize: '28px', color: '#F37021', marginTop:"50px" }}>教育 </Header>
                  <Header style={{ fontSize: '48px' }}>
                  ネイティブ英語教師を補う
                  </Header>
                  <Button style={{marginTop:'40px'}} color="orange" className="huge ui button" href="/sales/">お問い合わせ</Button>
                </Grid.Column>
                <Grid.Column style={{ marginLeft: "200px", marginTop:"-300px"}}>
                  <Image src="/edu-img.png" style={{width:'1000px', height:'536px', marginTop:'50px'}}/>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            }
            {isMobile &&
              <Grid container stackable verticalAlign="middle">
              <Grid.Row columns={2}>
                <Grid.Column style={{textAlign:"center"}}>
                  <Header as="h3" style={{marginTop:"15px", textAlign:"center"}}>教育 </Header>
                  <Header style={{ fontSize: '28px' }}>
                  ネイティブ英語教師を補う
                  </Header>
                  <Image style={{width:"311px", margin:"auto"}} src="/edu_img_mobile.png" />
                  <Button style={{marginTop:'40px'}} color="orange" className="huge ui button" href="/sales/">お問い合わせ</Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            }
        </Segment>

        <div className='solution-divider'></div>

        <h1 className='pageTopParth1' style={{textAlign:"center"}}>
        教育
        </h1>

        <Divider hidden />

        <Grid container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Image style={{width:isMobile ? "311px" : "auto" , margin:"auto"}} src="/edugirl.png" />
            </Grid.Column>

            <Grid.Column>
              <Header as="h3" style={{marginTop:"40px"}}>教育</Header>
              <Header as="h1">Muse 教育API</Header>
              <p style={{fontSize:"24px"}}>
              Museの包括的なコミュニケーション能力で学生を引き付けます。Museはテキストや会話、ジェスチャー、および顔の表情を理解します。
              </p>
              <List bulleted style={{fontSize:"24px"}}>
                <List.Item>
                  Muse API X CDL
                  <List.List>
                    <List.Item>Vena Talk</List.Item>
                    <List.Item>96％以上のコンバージョン率</List.Item>
                  </List.List>
                </List.Item>
                <List.Item>
                  Muse API X Softbank Robotics China
                  <List.List>
                    <List.Item>Pepper「ペッパー」</List.Item>
                    <List.Item>2,000個以上のPepper「ペッパー」</List.Item>
                  </List.List>
                </List.Item>
                <List.Item>Muse X オックスフォード大学出版局</List.Item>
              </List>
              {
                !isMobile &&
                <a href="https://launcher.akaai.io/kr" style={{ color: "#F2711C", fontSize:"24px" }}>
                詳しくはこちら <Icon name="arrow right" />
              </a>}

              {isMobile && <Divider hidden />}
              {isMobile && <Divider hidden />}
              {isMobile &&   <a  className='mobileLinkMainWatchVideo' href="/ai/chat/" style={{ color: "#F2711C" }}>Go to the Website <Icon name="arrow right" /></a>}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />

        {isMobile &&
            <Grid.Column>
              <Image style={{width:isMobile ? "311px" : "auto" , margin:"auto"}} src="/edu2.png" />
          </Grid.Column>}
          {isMobile && <Divider hidden />}
          {isMobile && <Divider hidden />}
        <Grid container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header as="h3">教育</Header>
              <Header as="h1">Musio English</Header>
              <p style={{fontSize:"24px"}}>
              Musioは公立学校で唯一検証されたAIロボットです。Museを搭載したMusioは、日本と韓国の210か所以上の教育機関で使用されており、Musioユーザーの70％以上が英語の使用頻度と自信が高まったと評価しています。

              </p>
              <List bulleted style={{fontSize:"24px"}}>
                <List.Item>MuseAPI X ケンブリッジ大学出版局</List.Item>
                <List.Item>
                MuseAPI X オンラインビデオ英語
                  <List.List>
                    <List.Item>Musio X Kimini（学研）</List.Item>
                    <List.Item>64.2％コンバージョン率</List.Item>
                  </List.List>
                </List.Item>
              </List>
              {isMobile && <Divider hidden />}
              {isMobile && <Divider hidden />}
              {!isMobile &&  <a href="https://musioenglish.kr/" style={{ color: "#F2711C" ,fontSize:"24px"}}>
              ウェブサイトに行く <Icon name="arrow right" />
              </a>}
              {isMobile &&   <a  className='mobileLinkMainWatchVideo' href="/ai/chat/" style={{ color: "#F2711C" }}>ウェブサイトに行く <Icon name="arrow right" /></a>}

            </Grid.Column>

            {!isMobile &&
            <Grid.Column>
              <Image style={{width:isMobile ? "311px" : "auto" , margin:"auto"}} src="/edu2.png" />
            </Grid.Column>}
          </Grid.Row>
        </Grid>

        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />

        <Grid container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Image style={{width:isMobile ? "311px" : "auto" , margin:"auto"}} src="/edu3.png" />
            </Grid.Column>

            <Grid.Column>
              <Header as="h3">教育</Header>
              <Header as="h1">コンテンツ出版 & パートナーシップ</Header>
              <List bulleted style={{width:"100%", fontSize:"24px", lineHeight:"150%" }}>
                <List.Item>
                クラスのAI主導化ができるよう、AKAは教育コンテンツをデジタル化し、それらをご提供できます。-180冊の教科書
                </List.Item>
                <List.Item>
                韓国の最大手教育企業（ハッカーズ教育グループ）の共同設立者が率いて制作した、最高のオリジナル教育コンテンツをご提供します。
                </List.Item>
                <List.Item>パートナーシップの目的と成果</List.Item>
                <List.Item>
                ベストセラーの教育会社（地元市場）との提携
                </List.Item>
                <List.Item>ターゲットユーザー向けのさまざまなレベルで出版社とコン</List.Item>
                <List.Item>テンツを開発</List.Item>
              </List>
              {isMobile && <Divider hidden />}
              {isMobile && <Divider hidden />}
              {!isMobile &&  <a href="#" style={{ color: "#F2711C", fontSize:"24px" }}>詳しくはこちら <Icon name="arrow right" /></a>}
              {isMobile &&   <a  className='mobileLinkMainWatchVideo' href="/ai/chat/" style={{ color: "#F2711C" }}>Go to the Website <Icon name="arrow right" /></a>}

            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div>
        <Divider hidden />
        <Divider hidden />

        <Divider hidden />
        <Divider hidden />
        </div>
        <Segment
          style={{ padding: isMobile ? "3em 0em" : "8em 0em", border: 0, backgroundColor: "#ffeee4" }}
          vertical
        >

          <Grid container stackable verticalAlign="middle">
            <Grid.Row columns={2}>
              {isMobile &&
                <Grid.Column>
                <Image style={{width:isMobile ? "103px" :"auto", float:'right'}} src="/edu5.png" />
                <Image  src="/edu4.png" />
              </Grid.Column>}

              <Grid.Column>
                <Header as="h3">Muse API 成功事例</Header>
                <Header as="h1">Chungdahm Learning Vena Talk</Header>
                <p style={{fontSize: '24px'}}>
                VENA TALKはMuseを利用した英語教育アプリで、子供向けの無制限のフリーチャットが可能です。練習、ロールプレイ、フリートークの3つのステップで、生徒は表現、話し方のパターンを学び、自由に話すことができます。
                </p>
                

                {!isMobile &&  <a href="https://apps.apple.com/kr/app/april%EB%B9%84%EB%82%98%ED%86%A1/id1486317712" style={{ color: "#F2711C", fontSize:"24px" }}>
                詳細を確認する <Icon name="arrow right" />
                </a>}
                {isMobile && <Divider hidden />}
                {isMobile && <Divider hidden />}
              {isMobile &&   <a  className='mobileLinkMainWatchVideo' href="/ai/chat/" style={{ color: "#F2711C" }}>Watch a video <Icon name="arrow right" /></a>}

              </Grid.Column>
                {!isMobile &&
                <Grid.Column>
                <Image src="/edu5.png" />
                <Image src="/edu4.png" />
              </Grid.Column>}
            </Grid.Row>

            {isMobile &&
            <div style={{width:"100%", display:"flex" , margin:"auto", justifyContent:"space-between"}} >
                <Image width="76px" src="/icon1.png" />
                <Image width="110px" src="/icon2.png" />
                <Image  width="75px"src="/icon3.png" />
            </div>
            }
            {!isMobile &&
            <Grid.Row columns={3}>
              <Grid.Column>
                <Image src="/icon1.png" />
              </Grid.Column>
              <Grid.Column>
                <Image src="/icon2.png" />
              </Grid.Column>
              <Grid.Column>
                <Image src="/icon3.png" />
              </Grid.Column>
            </Grid.Row>
            }
          </Grid>
        </Segment>
        <NextLevel />
      </>
    )
}
