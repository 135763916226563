import { createMedia } from "@artsy/fresnel";
import React, { Component } from "react";
import { Button, Card, Divider, Header, Image, List } from "semantic-ui-react";
import NextLevel from "../components/NextLevel";
import "../assets/global/components/pricingPage.css"
import { useMediaQuery } from 'react-responsive'
const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
});


export function Pricing() {
  const isMobile = useMediaQuery({query: '(max-width: 768px)'})

    return (
      <>
        <div style={{ padding: isMobile? "1em" : "4em" }} vertical>
          <Header textAlign="center" style={{ fontSize: "48px" }}>
            Pricing
            <Header.Subheader style={{fontSize:"24px", marginTop:"20px"}}>
              Select your pricing plan to start using Muse API
            </Header.Subheader>
          </Header>
          <Divider hidden />

          <div className="cardsCont">



            <div className='pricingCard' color="orange">
                <div className="cardTopOrangeLine"></div>
                <h4 className='pricingCardHeader' >Individual Purchase</h4>
                <br />
                <Card.Meta style={{fontSize:"42px", fontWeight:"700"}}>Per API</Card.Meta>
                <br />
              

                <Card.Description>
                  <Header as="h1" className="pricingCardList2" style={{ fontSize: "42px"}}>
                    $7
                  </Header>
                </Card.Description>
                <hr className='cardLine'/>
                <br />
                <div className='cardText'>
                  <p>If you want to use multiple APIs together, only 50% of each retail price will be charged.</p>
                  <br/>
                  <p>A royalty of 50% of the net receipts of each licensed product or USD 7 per user purchasing access, whichever is the greater, payable</p>
                </div>

                <div style={{marginTop: isMobile? "100px" : "50px" , width:"100%" , height:"54"}}>
                  <Button  className="pricingButton" href="/ai/chat/" fluid color="orange" inverted>
                    <div className="pricingButtonDiv">
                      Start a Free Trial
                    </div>
                  </Button>
                </div>
            </div>




            <div className='pricingCard' color="orange">
              <div className="cardTopOrangeLine"></div>
          

                <div>
                  <h4 className='pricingCardHeader'>Package Purchase </h4>
                </div>
                <br />
                <Card.Meta style={{fontSize:"42px", fontWeight:"700"}}>Contact Us</Card.Meta>
                <br />
                {/* <div style={{display:"flex" , flexDirection:"column" , marginTop:"12px", fontSize:"18px"}}>
                  <Header as="h1" style={{color:'black'}} style={{margin:"0px" , color:"black"}}>Contact Us</Header>
                </div> */}
                <Card.Description>
                  <ul className='pricingCardList'>
                      <li>English Education API Package</li>
                      <li>
                        Digital Remedy API (Cognitive Impairment) Package
                      </li>
                      <li>
                        Driving Companion API Package
                      </li>
                      <li>Smart Farming API package</li>
                  </ul>
                </Card.Description>

                <hr className='cardLine' style={{marginTop:isMobile? "100px" : ""}}/>
                <br />
                <div className='cardText' style={{color:"#6B7480", fontSize:"24px",  lineHeight:"150%", height:isMobile? "150px" : ""}}>For further details on pricing and package composition</div>
                {" "}

                <div style={{marginTop:isMobile ? "20px" :"50px" , width:"100%", height:"54"}}>
                  <Button  className="pricingButton " href="/sales/" fluid color="orange" >
                    <div className="pricingButtonDiv">
                      Talk to Sales
                      </div>
                  </Button>
                </div>
                <div>
                  
                </div>
            </div>




          </div>
        </div>{" "}


        <Divider hidden />
        <Divider hidden />


        <div style={{ padding: isMobile ? "40px 32px" : "80px 80px", width:"100%", boxSizing:"border-box", backgroundColor: "#ffeee4" , display:'flex' ,flexDirection:"column" , alignItems:"center"}} vertical>
          <Header textAlign="center" style={{ fontSize: "42px" }}>
            Education API Package
          </Header>
          <Divider hidden />
          <Divider hidden />
          <div style={{margin:"auto" , display:"flex" , columnGap:"48px" , flexWrap:"wrap" ,   rowGap:"20px",}}>
            <div className='eduApiSectionCard' style={{ width: isMobile ? "100%" : 500, boxSizing:"border-box" , height:isMobile ? "auto" : "450px"  }}>
                <h4 className='eduApiCardHeader'>Chat API </h4>
                <hr className='eduApiCardLine'/> 
                <ul className="eduApiSectionList">
                  <li>
                    <Image avatar src="/check.png" />
                    <List.Content>Learner's Mode</List.Content>
                  </li>
                  <li>
                    <Image avatar src="/check.png" />
                    <List.Content>Chat Log API</List.Content>
                  </li>
                  <li>
                    <Image avatar src="/check.png" />
                    <List.Content>
                      Analysis API  (including Learner's Chat Analysis)
                    </List.Content>
                  </li>
                  <li>
                    <Image avatar src="/check.png" />
                    <List.Content>Easy Mode (Muse Free Talk)</List.Content>
                  </li>
                </ul>
            </div>



            <div className='eduApiSectionCard' style={{  width: isMobile ? "100%" : 500, boxSizing:"border-box" ,height:isMobile ? "auto" : "450px" }}>
                <h4 className='eduApiCardHeader'>Corpus API </h4>
                <hr className='eduApiCardLine'/> 
                <ul className="eduApiSectionList">
                  <li>
                    <Image avatar src="/check.png" />
                    <List.Content>User List</List.Content>
                  </li>
                  <li>
                    <Image avatar src="/check.png" />
                    <List.Content>Tree List</List.Content>
                  </li>
                  <li>
                    <Image avatar src="/check.png" />
                    <List.Content>
                      User Progress
                    </List.Content>
                  </li>
                  
                </ul>
            </div>


          </div>
        </div>{" "}
        <NextLevel />
      </>
    );
}
