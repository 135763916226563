import React, { Component } from "react";
import AliceCarousel from "react-alice-carousel";
import { connect } from "react-redux";
import {
  Container,
  Divider,
  Grid,
  Header,
  Image,
  Tab,
} from "semantic-ui-react";
import Visualization from "../components/AIAnalysisVisualization";
import NextLevel from "../components/NextLevel";
import { fetchAnalysis } from "../redux/actions/actions";
import "../assets/global/components/aiAnalaysis.css"

const panes = [
  {
    menuItem: "事例",
    render: () => (
      <Tab.Pane style={{ padding: 40 }}>
        <Header as="h1" style={{ fontSize: "2em" }}>
          Muse APIを利用したアプリケーションの事例
        </Header>
        <Container>
          <Header as="h1" style={{ fontSize: "1.5em" }} textAlign="center">
            Muse English
          </Header>

          <AliceCarousel autoPlay autoPlayInterval="3000">
            <Image rounded raised src={"/analysis_example_3.png"} />
            <Image rounded raised src={"/analysis_example_2.png"} />
            <Image rounded raised src={"/analysis_example_4.png"} />
            <Image rounded raised src={"/analysis_example_1.png"} />
          </AliceCarousel>
          {/* 
          <Image.Group>
            <Image src="/analysis_example_3.png" />
            <Image src="/analysis_example_2.png" />
            <Image src="/analysis_example_4.png" />
            <Image src="/analysis_example_1.png" />
          </Image.Group> */}
        </Container>
      </Tab.Pane>
    ),
  },
  {
    menuItem: "チャットログから",
    render: () => (
      <Tab.Pane>
        <Visualization />
      </Tab.Pane>
    ),
  },
];

export class AIAnalysis_jp extends Component {
  componentDidMount() {
    this.props.dispatch(fetchAnalysis());
  }
  render() {
    return (
      <>
        <div style={{ backgroundColor: "#f1f1f1" }}>
          <Container textAlign="center" style={{ paddingTop: "3em" }}>
            <h3 className="aiAnlyzPage3">MUSE API 01</h3>
            <h1 className="aiAnlyzPage1">
              MUSE AI 分析API
            </h1>
            {/* <p>지금 MUSE AI분석 API를 체험해 보세요</p> */}
          </Container>

          <Container style={{ padding: "2em" }}>
            <Tab panes={panes} />

            {/* <Segment raised style={{ paddingTop: 40 }}>
              <Visualization />
            </Segment> */}
          </Container>
        </div>
        <Divider hidden />
        <Divider hidden />
        <h1 className="aiAnlyzPage1" style={{textAlign:"center" , marginTop:"100px"}}>
          Muse AI 分析 API  <div className='fullWidthMobileBlock'></div> （選択済み）
        </h1>
        <div className="mobileDiveder"></div>

        <div className="aiAnlyzPageElementsDown" >
          <div className="aiAnalyzeMobileBlock">
          <Grid container stackable verticalAlign="top">
            <Grid.Row columns={3}>
              <Grid.Column>
                <Image width="90px" src="/analysis1.jpg" />
                <Header style={{fontSize:"28px"}}>読解力測定</Header>
                <p style={{fontSize:"24px"}}>
                  話した文章と聞き取った文章の難易度から読解力を測定します
                </p>
                <div className="mobileDiveder"></div>
                <div className="mobileDiveder"></div>
                <div className="mobileDiveder"></div>

              </Grid.Column>

              <Grid.Column>
                <Image width="90px" src="/analysis2.png" />
                <Header style={{fontSize:"28px"}}>
                  AI分析文章の変化と文脈の関連性
                </Header>
                <p style={{fontSize:"24px"}}>
                  様々な話し言葉のパターンとタイプを分析します。{" "}
                </p>
                <p style={{fontSize:"24px"}}>会話の文脈関連性を分析します。</p>
                <div className="mobileDiveder"></div>
                <div className="mobileDiveder"></div>
                <div className="mobileDiveder"></div>
              </Grid.Column>
              <Grid.Column>
                <Image width="90px" src="/analysis3.png" />
                <Header style={{fontSize:"28px"}}>文章の長さ</Header>
                <p style={{fontSize:"24px"}}>決まった基準で文章の長さを測定します</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          </div>


          <div className="mobileDiveder"></div>

          <Grid container stackable verticalAlign="top">
            <Grid.Row columns={3}>
              <Grid.Column>
                <Image width="90px" src="/measurement3.png" />
                <Header style={{fontSize:"28px"}}>文法チェックAPI</Header>
                <p style={{fontSize:"24px"}}>
                 話された全ての文章の文法エラーをチェックします
                </p>
                <div className="mobileDiveder"></div>
                <div className="mobileDiveder"></div>
                <div className="mobileDiveder"></div>
              </Grid.Column>
              <Grid.Column>
                <Image width="90px" src="/analysis5.png" />
                <Header style={{fontSize:"28px"}}>
                  相関スコアの測定
                </Header>
                <p style={{fontSize:"24px"}}>
                  話された文章と質問の間の相関スコアを測定します
                </p>
                <div className="mobileDiveder"></div>
                <div className="mobileDiveder"></div>
                <div className="mobileDiveder"></div>
              </Grid.Column>
              <Grid.Column>
                <Image width="90px" src="/analysis6.png" />
                <Header style={{fontSize:"28px"}}>話された文章をパーツに分けて抽出します</Header>
                <p>話された文章の各単語を分類し、抽出します</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <Divider hidden />
        <Divider hidden />

        <NextLevel />
      </>
    );
  }
}

AIAnalysis_jp = connect((store) => {
  return {
    data: store.data,
  };
})(AIAnalysis_jp);
