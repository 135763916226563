import React from "react";
import { Link } from "react-router-dom";
import nextLevelCoverImage from "../assets/next-level-main-image.png";

export default function NextLevel() {
  return (
    <div className="next-level-container">
      <div className="next-level-content">
        <h3 className="next-level-h3" style={{fontSize: "42px"}}>Museについてもっと知る</h3>
        <Link className="next-level-button" to="/sales" style={{fontSize:"24px"}}>
        お問い合わせ
        </Link>
      </div>
      <div className="next-level-img-container">
        <img src={nextLevelCoverImage} alt="next-level" />
      </div>
    </div>
  );
}
