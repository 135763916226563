import { color } from "echarts";
import React from "react";
import { Container, Grid, Header, List, Segment, Accordion, Icon } from "semantic-ui-react";

export default function Footer_cn() {
  const [open, setOpen] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState();

  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index

    setActiveIndex(newIndex);
  }

  return (
    <Segment vertical style={{ padding: "5em 0em" }} inverted>
        <div style={{ paddingLeft: "32px" }}>
        <Accordion inverted className="hide-in-desktop">
            <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={handleClick}
            >
                <Icon name='dropdown' />
                产品
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
            <div style={{ paddingLeft: "20px" }}>
                <List link inverted>
                    <List.Item as="a" href="/cn/">
                    缪斯API.
                    </List.Item>
                    <List.Item as="a" href="/cn/ai/analysis/">
                    缪斯分析API.
                    </List.Item>
                    <List.Item as="a" href="/cn/ai/chat/">
                    缪斯聊天API.
                    </List.Item>
                    <List.Item as="a" href="/cn/ai/vision/">
                    Muse Vision API.
                    </List.Item>
                </List>
            </div>
            </Accordion.Content>

            <Accordion.Title
                active={activeIndex === 1}
                index={1}
                onClick={handleClick}
            >
            <Icon name='dropdown' />
            解决方案
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
            <div style={{ paddingLeft: "20px" }}>
                <List link inverted>
                    <List.Item as="a" href="/cn/solution/education">
                    教育
                    </List.Item>
                    <List.Item as="a" href="/cn/solution/enterprise">
                    企业
                    </List.Item>
                    <List.Item as="a" href="/cn/solution/health">
                    医疗保健/幸福
                    </List.Item>
                    <List.Item as="a" href="/cn/solution/mobility">
                    移动性
                    </List.Item>
                </List>
            </div>
            </Accordion.Content>

            <Accordion.Title
                active={activeIndex === 2}
                index={2}
                onClick={handleClick}
            >
            <Icon name='dropdown' />
            价钱
            </Accordion.Title>
            <Accordion.Content>
            <div style={{ paddingLeft: "20px" }}>
                {/* Here will be Pricing Content */}
            </div>
            </Accordion.Content>

            <Accordion.Title
                active={activeIndex === 3}
                index={3}
                onClick={handleClick}
            >
            <Icon name='dropdown' />
            公司
            </Accordion.Title>
            <Accordion.Content>
            <div style={{ paddingLeft: "20px" }}>
                {/* Here will be Company Content */}
            </div>
            </Accordion.Content>
        </Accordion>
        </div>
      <Container className="hide-in-mobile">
        <Grid stackable>
          <Grid.Row columns={4}>
            <Grid.Column>
              <Header as="a" content="产品" inverted />
              <List link inverted>
                <List.Item as="a" href="/cn/">
                缪斯API.
                </List.Item>
                <List.Item as="a" href="/cn/ai/analysis/">
                缪斯分析API.
                </List.Item>
                <List.Item as="a" href="/cn/ai/chat/">
                缪斯聊天API.
                </List.Item>
                <List.Item as="a" href="/cn/ai/vision/">
                Muse Vision API.
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <Header as="a" content="解决方案" inverted />
              <List link inverted>
                <List.Item as="a" href="/cn/solution/education">
                教育
                </List.Item>
                <List.Item as="a" href="/cn/solution/enterprise">
                企业
                </List.Item>
                <List.Item as="a" href="/cn/solution/health">
                医疗保健/幸福
                </List.Item>
                <List.Item as="a" href="/cn/solution/mobility">
                移动性
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <Header as="a" href="/cn/pricing" content="价钱" inverted />
            </Grid.Column>
            <Grid.Column>
              <Header
                as="a"
                href="https://www.akaintelligence.com/"
                content="公司"
                inverted
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
}
