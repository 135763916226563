import { createMedia } from "@artsy/fresnel";
import React, { Component } from "react";
import {
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  Segment,
} from "semantic-ui-react";
import NextLevel from "../components/NextLevel";
import { useMediaQuery } from 'react-responsive'

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
});
export function SolutionHealth() {

    const isMobile = useMediaQuery({query: '(max-width: 768px)'})
    return (
      <>
        <Segment
          style={{
            border: 0,
            padding: "0em 0em 0em 0em",
            backgroundColor: "#ffeee4",
          }}
          vertical
        >
          {!isMobile &&
            <Grid container stackable verticalAlign="middle">
            <Grid.Row style={{ height: "500px" }} columns={1}>
              <Grid.Column width={8}>
                <Header style={{fontSize: '28px', color: '#F37021', marginTop:"50px"}}>
                  HEALTHCARE WELL BEING
                </Header>
                <Header style={{fontSize: '48px'}}>
                  Supplement the Caregiver
                </Header>
                <Button style={{marginTop:'40px'}}color="orange" className="huge ui button" href="/sales/">Talk to Sales</Button>
              </Grid.Column>
              <Grid.Column style={{ marginLeft: "200px", marginTop:"-300px"}}>
                <Image src="/healthsvg.png" style={{width:'1000px', height:'536px'}} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          }

          {isMobile && 
            <Grid container stackable verticalAlign="middle">
              <Grid.Row columns={2}>
                <Grid.Column style={{textAlign:"center"}}>
                  <Header as="h3" style={{marginTop:"15px", textAlign:"center"}}>HEALTHCARE WELL BEING </Header>
                  <Header style={{ fontSize: '28px' }}>
                  Supplement the Caregiver
                  </Header>
                  <Image style={{width:"311px", margin:"auto"}} src="/health-mobile.png" />
                  <Button style={{marginTop:'40px'}} color="orange" className="huge ui button" href="/sales/">Talk to Sales</Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          }
          
        </Segment>
          
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />

        <Header as="h1" className='pageTopParth1' textAlign="center" style={{marginTop:"100px" }}>
          Healthcare / Well Being
        </Header>

        <Divider hidden />

        <Grid container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Image src="/health1.png" />
            </Grid.Column>

            <Grid.Column>
              <Header as="h3" style={{marginTop:"100px"}}>Healthcare / Well being</Header>
              <Header as="h1">ReSmart</Header>
              <p style={{fontSize:"24px"}}>
                ReSmart provides AI life-coaching for all. Its brain training program, meditation, 
                and daily recommended activities are designed to improve cognitive skills in five different areas. 
                Relieve stress and depression risk while keeping your body and brain fit with daily activities. 
                See continuous improvement with ReSmart's progress-tracking system. 
              </p>

              <a href="https://akaon.com/resmart" style={{ color: "#F2711C", fontSize:"24px" }}>
                Learn more <Icon name="arrow right" />
              </a>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Divider hidden />

        <Divider hidden />
        <Divider hidden />

        <Grid container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column style={{marginTop:"100px"}}>
              <Header as="h3">Healthcare / Well Being</Header>
              <Header as="h1">Musio Alive X Selective Mutism</Header>
              <p style={{fontSize:"24px"}}>
                The use of AI robots to educate disabled students was verified in a middle school in Japan. Tests indicated that AI robots could help students overcome their selective mutism and communicate more freely.{" "}
              </p>
              <p style={{fontSize:"24px"}}>
                Students that were initially passive about learning foreign languages became more interested in the subject. They showed improved in-class attitudes and a keen focus on pronunciation practice.{" "}
              </p>
              <a href="https://akaon.com/resmart" style={{ color: "#F2711C" ,fontSize:"24px" }}>
                Learn more <Icon name="arrow right" />
              </a>
            </Grid.Column>

            <Grid.Column>
              <Image src="/health2.png" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Divider hidden />

        <Divider hidden />
        <Divider hidden />

        <NextLevel />
      </>
    );
  
}
