import { createMedia } from "@artsy/fresnel";
import React, { Component } from "react";
import AliceCarousel from "react-alice-carousel";
import { connect } from "react-redux";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Image,
  Segment,
} from "semantic-ui-react";
import NextLevel from "../components/NextLevel";
import { fetchAnalysis } from "../redux/actions/actions";

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
});
export class AIVision_cn extends Component {
  componentDidMount() {
    this.props.dispatch(fetchAnalysis());
  }
  render() {
    return (
      <>
        <div style={{ backgroundColor: "#f1f1f1" }}>
          <Container textAlign="center" style={{ paddingTop: "3em" }}>
            <Header as="h3">MUSE API 03</Header>
            <Header as="h1" style={{ fontSize: "48px" }}>
              MUSE AI Vision API
            </Header>
            {/* <p>지금 MUSE Vision API를 체험해 보세요</p> */}
          </Container>

          <Container style={{ padding: "2em" }}>
            <Segment raised style={{ paddingTop: 40 }} textAlign="center">
              {/* <Visualization /> */}

              <Header>パーム油果実の熟度検出への応用</Header>
              <AliceCarousel autoPlay autoPlayInterval="3000">
                <Image src="/palm1.png" />
                <Image src="/palm3.png" />
                <Image src="/palm4.png" />
                <Image src="/palm5.png" />
              </AliceCarousel>
              <Divider hidden />

              <Button
                color="orange"
                href="https://www.youtube.com/watch?v=I0WporBtMn8"
              >
                動画を見る
              </Button>
              <Divider />
              <Header>
              自動屋内植物検出/識別への応用
              </Header>
              <AliceCarousel autoPlay autoPlayInterval="3000">
                <Image src="/indoor_plant_detection.png" />
                <Image src="/indoor_plant_analysis.png" />
              </AliceCarousel>
              <Divider hidden />

              <Button
                href="https://www.youtube.com/watch?v=GpN6yfDqhAY"
                color="orange"
              >
                動画を見る
              </Button>
            </Segment>
          </Container>
        </div>
        <Divider hidden />
        <Divider hidden />

        <Header as="h1" textAlign="center" style={{ fontSize: "48px" }}>
          Muse AI Vision API
        </Header>
        <Divider hidden />
        <Divider hidden />

        <div style={{ padding: 50 }}>
          <Grid container stackable verticalAlign="top">
            <Grid.Row columns={3}>
              <Grid.Column>
                <Image width="90px" src="/Framevision1.png" />
                <Header style={{fontSize:"28px"}}>顔の表情の分析</Header>
                <p style={{fontSize:"24px"}}>
                AIが返事を提案する、Corpus（コーパス）ベースの協力型会話システム{" "}
                </p>
              </Grid.Column>
              <Grid.Column>
                <Image width="90px" src="/Framevision2.png" />
                <Header style={{fontSize:"28px"}}>物体検出</Header>
                <p style={{fontSize:"24px"}}> 
                学習教材（本など）ベースのAI会話エンジン
                </p>
              </Grid.Column>
              <Grid.Column>
                <Image width="90px" src="/Framevision3.png" />
                <Header style={{fontSize:"28px"}}>シーンの説明</Header>
                <p style={{fontSize:"24px"}}>
                リアルタイムのフィードバックができるカリキュラムベースの繰り返し学習システム
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider hidden />

          <Grid container stackable verticalAlign="top">
            <Grid.Row columns={3}>
              <Grid.Column>
                <Image width="90px" src="/Framevision4.png" />
                <Header style={{fontSize:"28px"}}>人間の検出</Header>
                <p style={{fontSize:"24px"}}> 
                文章の中で重要な単語・パーツ（お名前や地名など）を抽出します
                </p>
              </Grid.Column>
              <Grid.Column>
                <Image width="90px" src="/Framevision5.png" />
                <Header style={{fontSize:"28px"}}>植物の識別</Header>
                <p style={{fontSize:"24px"}}>
                文章の中で重要な単語・パーツ（お名前や地名など）を抽出します
                </p>
              </Grid.Column>
              <Grid.Column>
                <Image width="90px" src="/Framevision6.png" />
                <Header style={{fontSize:"28px"}}>植物ステータスの識別</Header>
                <p style={{fontSize:"24px"}}>
                文章の中で重要な単語・パーツ（お名前や地名など）を抽出します
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <Divider hidden />
        <Divider hidden />

        <NextLevel />
      </>
    );
  }
}

AIVision_cn = connect((store) => {
  return {
    data: store.data,
  };
})(AIVision_cn);
