import { createMedia } from "@artsy/fresnel";
import React, { Component } from "react";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  Segment,
} from "semantic-ui-react";
import NextLevel from "../components/NextLevel";
import SolutionAccordion from "../components/SolutionAccordion";
import "../styles/queries.css";
import "../assets/mobile/Home.css"
import "../assets/global/components/home.css"
import { useMediaQuery } from 'react-responsive'

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
});

export function Home() {
  const isMobile = useMediaQuery({query: '(max-width: 768px)'})

  return (<>

        <Container>
          <Grid container stackable verticalAlign="middle">
            <Grid.Row columns={2}>
              <Grid.Column width={7}>
                <div className="mobileAiImageCont" style={{marginTop: "3em"}}> 
                  <Image src="/muse.png" />
                </div>
                
                 <h1 className='mainTopHeader'>AI Communication Engine</h1> 
               
                <p className="mainTopContent">
                  Muse uses Deep Learning, Big Data, NLP, and Machine Vision to deliver essential capabilities in Education, Healthcare, Farming, and more.
                </p>
                {
                !isMobile &&
                  <>
                  <Divider hidden />{" "}
                  <Divider hidden />{" "}
                  <Divider hidden />{" "}
                  </>
                }
                {
                isMobile &&
                <Grid.Column width={9}>
                  <Image src="/homepagelogo.png" width='310px' style={{margin:"auto"}}/>
                </Grid.Column>
                }

                <div className='mainButtonContTop'>
                <Button  href="/sales/" color="orange" className="huge ui button" >
                  Talk to Sales
                </Button>
                {/* <Button
                  href="https://apps.apple.com/kr/app/april%EB%B9%84%EB%82%98%ED%86%A1/id1486317712"
                  inverted
                  color="orange"
                  className="huge ui button"
                >
                  Start a free trial
                </Button> */}

                </div>
               
              </Grid.Column>
              {!isMobile &&
                <Grid.Column width={9}>
                  <Image src="/homepagelogo.png"/>
                </Grid.Column>
              }
              
            </Grid.Row>
          </Grid>
        </Container>
        {
          !isMobile &&
            <>
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            </>
        }

        { isMobile &&<Divider hidden />}


        <Grid
          celled="internally"
          columns="equal"
          stackable
          style={{ padding:isMobile ? "1em 0em" :  "4em 0em", backgroundImage: "url(/watchvid.png)" }}
        >
          <Grid.Row textAlign="center">
            <Grid.Column style={{ paddingBottom: "5em", paddingTop: "5em" }}>
              <Header as="h3">
              <p className="mobileMainTextWidth">
              MUSE APIs Enable Near-Human Communication
              </p>
              </Header>
              <Divider hidden />
              <Divider hidden />
              <Divider hidden />
              {/* <a className="mobileLinkMainWatchVideo" href="#" style={{ color: "#F2711C" }}>Watch a video <Icon name="arrow right" /></a> */}
              <p className="mobileSubTextWidth" style={{color:"#F2711C"}}>Stand Out From The Competition With Lifelike AI Cognition</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {
          !isMobile &&
            <>
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            </>
        }

      {
          isMobile &&
            <>
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            </>
        }

        <Grid container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header as="h3" style={{color:"#6B7480"}}>MUSE API 01</Header>
              <Header as="h1">MUSE AI Analysis API</Header>
              <p className="changeTextWidth">
                Muse Analysis provides analysis of user conversations. It analyzes the diversity of users' 
                spoken sentences by recognizing sentence structures and patterns. 
                It assesses spoken sentences, words, pronunciation, even conversational context.{" "}
              </p>
              <a className="mobileLinkMainWatchVideo" href="/ai/analysis/" style={{ color: "#F2711C" }}>Learn More <Icon name="arrow right" /></a>
            </Grid.Column>
            { !isMobile &&
              <Grid.Column>
              <Image src="/introanalysis.png"/>
              </Grid.Column>
            }
          </Grid.Row>
        </Grid>

        { !isMobile &&
        <div>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        </div>
        }

      { isMobile &&
        <div>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        </div>
        }
        

        <Grid className='mobileVersionMuseApi' container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            { !isMobile &&
              <Grid.Column>
                <Image src="/mobilechat.png"/>
              </Grid.Column>
            }
            <Grid.Column>
              <Header as="h3" style={{color:"#6B7480"}}>MUSE API 02</Header>
              <Header as="h1">MUSE AI Chat API</Header>
              <p className="changeTextWidth">
                Muse Chat uses speech recognition and voice synthesis technology 
                to convert speech to text, and vice versa. It draws on textbooks 
                and educational contents to provide chatbots, suggest spoken responses, 
                and lead English-learning classes.{" "}
              </p>
              <a  className='mobileLinkMainWatchVideo' href="/ai/chat/" style={{ color: "#F2711C" }}>Learn More <Icon name="arrow right" /></a>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        { !isMobile &&
        <div>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        </div>
        }

      { isMobile &&
        <div>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        </div>
        }
        
        
        <Grid className='mobileVersionMuseApi' container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header as="h3" style={{color:"#6B7480"}}>MUSE API 03</Header>
              <Header as="h1">MUSE AI Vision API</Header>
              <p className="changeTextWidth">
                Muse Vision visually recognizes users and their facial expressions. 
                It also identifies objects, distinguishing between plant and fruit, 
                the condition of plants, and their suitability for harvesting.{" "}
              </p>

              <a className='mobileLinkMainWatchVideo' href="/ai/vision/" style={{ color: "#F2711C" }}>Learn More <Icon name="arrow right" /></a>

            </Grid.Column>
            { !isMobile &&
            <Grid.Column>
              <Image src="/homevision.png"/>
            </Grid.Column>
            }
          </Grid.Row>
        </Grid>

        { !isMobile &&
        <div>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        </div>
        }

      { isMobile &&
        <div>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        </div>
        }
        

        <Header as="h1" textAlign="center" style={{ fontSize: "3em" }}>
          Applications
        </Header>

        { !isMobile &&
        <div>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>
        </div>
        }

        <SolutionAccordion isMobile={isMobile} />

        <Divider hidden />
        <Divider hidden />




        <Segment
          style={{
            padding: isMobile ? "6em 1em" : "8em",
            border: 0,
            backgroundColor: "#f7f7f8",
          }}
          vertical
        >
          <Grid container stackable verticalAlign="middle">
            <Grid.Row columns={2}>
              <Grid.Column>
                <Header style={{fontSize: "15px", margin:"0px", color: "#6B7480"}} as="h3">Muse API Success Cases</Header>
                <Header as="h1">Chungdahm Learning: Vena Talk</Header>
                <p style={{fontSize:"24px"}}>
                  VENA TALK is a Muse-powered, corpus-based English education app with unlimited free-chat for children. In three steps, 1. Practice, 2. Role Play, and 3. Free Talk, students learn expressions, patterns, and how to talk freely.
                </p>

              {!isMobile &&<a
                href="https://apps.apple.com/kr/app/april%EB%B9%84%EB%82%98%ED%86%A1/id1486317712"
                style={{ color: "#F2711C", fontSize:"24px" }}
              >
                Check It Out <Icon name="arrow right" />
              </a>}
              {isMobile &&
                <div>
              <Divider hidden />
              <Divider hidden />
              <Divider hidden />
              </div>}

              {isMobile &&<a className="mobileLinkMainWatchVideo" href="#" style={{ color: "#F2711C" }}>Watch a video <Icon name="arrow right" /></a>}


              </Grid.Column>
              {!isMobile && <Grid.Column>
                <Image src="/edu5.png"/>
                <Image src="/edu4.png"/>
              </Grid.Column>}
            </Grid.Row>
          </Grid>

          {!isMobile &&
          <Grid stackable style={{ paddingTop: isMobile ? "1em" : "8em" }} centered columns={2}>
            <Grid.Row columns={3}>
              <Grid.Column textAlign="center">
                <Image width={isMobile ? "83px" : 'auto'} src="/logo_softbank.png" />
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Image  width={isMobile ? "83px" : 'auto'} src="/logo_kddi.png" />
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Image  width={isMobile ? "61px" : 'auto'} src="/logo_speecys.png" />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={3}>
              <Grid.Column>
                <Image   width={isMobile ? "109px" : 'auto'}  src="/logo_rakuten.png" />
              </Grid.Column>
              <Grid.Column>
                <Image  width={isMobile ? "94px" : 'auto'}  src="/logo_kanji.png" />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          }
          {isMobile &&
          <div style={{display:"flex" , marginTop:"46px", flexWrap:"wrap" , columnGap:"38px", justifyContent:"center" , rowGap:"35px"}}>
                <Image width={isMobile ? "83px" : 'auto'} src="/logo_softbank.png" />
                <Image  width={isMobile ? "83px" : 'auto'} src="/logo_kddi.png" />
                <Image  width={isMobile ? "61px" : 'auto'} src="/logo_speecys.png" />
                <Image   width={isMobile ? "109px" : 'auto'}  src="/logo_rakuten.png" />
                <Image  width={isMobile ? "94px" : 'auto'}  src="/logo_kanji.png" />
          </div>
          }
        </Segment>


          {!isMobile &&

            <div>
              <Divider hidden />
              <Divider hidden />
              <Divider hidden />
              <Divider hidden />
              <Divider hidden/>
              <Divider hidden/>
              <Divider hidden/>
              <Divider hidden/>
            </div>
          }
        

        <NextLevel />
      </>
  );
}



