import { createMedia } from "@artsy/fresnel";
import React, { Component } from "react";
import { Button, Card, Divider, Header, Image, List } from "semantic-ui-react";
import NextLevel from "../components/NextLevel";
import "../assets/global/components/pricingPage.css"
import { useMediaQuery } from 'react-responsive'
const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
});


export function Pricing_kr() {
  const isMobile = useMediaQuery({query: '(max-width: 768px)'})

    return (
      <>
        <div style={{ padding: isMobile? "1em" : "4em" }} vertical>
          <Header as="h1" textAlign="center" style={{ fontSize: "48px" }}>
            가격 안내
            <Header.Subheader style={{fontSize:"24px", marginTop:"20px"}}>
              구매 옵션을 선택해주세요.
            </Header.Subheader>
          </Header>
          <Divider hidden />

          <div className="cardsCont">



            <div className='pricingCard' color="orange">
                <div className="cardTopOrangeLine"></div>
                <h4 className='pricingCardHeader'>개별구매 가격 </h4>
                <br />
                <Card.Meta style={{fontSize:"42px", fontWeight:"700"}}>API당</Card.Meta>
                <br />
              

                <Card.Description>
                  <Header as="h1" className="pricingCardList2" style={{ fontSize: "42px"}}>
                    $7
                  </Header>
                </Card.Description>
                <hr className='cardLine'/>
                <br />
                <p className='cardText'>
                  {" "}
                  <p>"여러 개의 API를 사용하고 싶으신 경우, 리테일 가격의 50%만 청구됩니다.</p>
                </p>

                <div style={{marginTop: isMobile? "100px" : "50px" , width:"100%" , height:"54"}}>
                  <Button  className="pricingButton" href="/ai/chat/" fluid color="orange" inverted>
                    <div className="pricingButtonDiv">
                      무료체험 시작하기
                    </div>
                  </Button>
                </div>
            </div>




            <div className='pricingCard' color="orange">
              <div className="cardTopOrangeLine"></div>
          

                <div>
                  <h4 className='pricingCardHeader'>패키지 구매 </h4>
                </div>
                <br />
                <Card.Meta style={{fontSize:"42px", fontWeight:"700"}}>문의하기</Card.Meta>
                <br />
                {/* <div style={{display:"flex" , flexDirection:"column" , marginTop:"12px", fontSize:"18px"}}>
                  <Header as="h1" style={{color:'black'}} style={{margin:"0px" , color:"black"}}>Contact Us</Header>
                </div> */}
                <Card.Description>
                  <ul className='pricingCardList'>
                      <li>영어 교육 API 패키지</li>
                      <li>
                        디지털 치료 API (인지장애) 패키지
                      </li>
                      <li>
                        미래의 커넥티드 카를 위한 드라이빙 컴패니언 API 패키지
                      </li>
                      <li>스마트 파밍 API 패키지</li>
                  </ul>
                </Card.Description>

                <hr className='cardLine' style={{marginTop:isMobile? "100px" : ""}}/>
                <br />
                <div className='cardText' style={{color:"#6B7480", fontSize:"24px",  lineHeight:"150%", height:isMobile? "150px" : ""}}>가격 또는 패키지 구성에 대해 궁금한 점이 있으시면 문의해 주시기 바랍니다.</div>
                {" "}

                <div style={{marginTop:isMobile ? "20px" :"70px" , width:"100%", height:"54"}}>
                  <Button  className="pricingButton " href="/sales/" fluid color="orange" >
                    <div className="pricingButtonDiv">
                      구매 상담
                      </div>
                  </Button>
                </div>
                <div>
                  
                </div>
            </div>





          </div>
        </div>{" "}


        <Divider hidden />
        <Divider hidden />


        <div style={{ padding: isMobile ? "40px 32px" : "80px 80px", width:"100%", boxSizing:"border-box", backgroundColor: "#ffeee4" , display:'flex' ,flexDirection:"column" , alignItems:"center"}} vertical>
          <Header as="h1" textAlign="center" style={{ fontSize: "42px" }}>
            교육 API 패키지
          </Header>
          <Divider hidden />
          <Divider hidden />
          <div style={{margin:"auto" , display:"flex" , columnGap:"48px" , flexWrap:"wrap" ,   rowGap:"20px",}}>
            <div className='eduApiSectionCard' style={{ width: isMobile ? "100%" : 500, boxSizing:"border-box" , height:isMobile ? "auto" : "450px"  }}>
                <h4 className='eduApiCardHeader'>대화 API </h4>
                <hr className='eduApiCardLine'/> 
                <ul className="eduApiSectionList">
                  <li>
                    <Image avatar src="/check.png" />
                    <List.Content>학습자 모드</List.Content>
                  </li>
                  <li>
                    <Image avatar src="/check.png" />
                    <List.Content>채팅 로그 API </List.Content>
                  </li>
                  <li>
                    <Image avatar src="/check.png" />
                    <List.Content>
                      분석 API (학습자의 챗 분석 포함)
                    </List.Content>
                  </li>
                  <li>
                    <Image avatar src="/check.png" />
                    <List.Content>쉬움 모드 (뮤즈 프리토킹)</List.Content>
                  </li>
                </ul>
            </div>



            <div className='eduApiSectionCard' style={{  width: isMobile ? "100%" :500, boxSizing:"border-box" ,height:isMobile ? "auto" : "450px" }}>
                <h4 className='eduApiCardHeader'>코퍼스 API </h4>
                <hr className='eduApiCardLine'/> 
                <ul className="eduApiSectionList">
                  <li>
                    <Image avatar src="/check.png" />
                    <List.Content>사용자 목록</List.Content>
                  </li>
                  <li>
                    <Image avatar src="/check.png" />
                    <List.Content>트리 목록</List.Content>
                  </li>
                  <li>
                    <Image avatar src="/check.png" />
                    <List.Content>
                      사용자 진행 상황
                    </List.Content>
                  </li>
                  
                </ul>
            </div>


          </div>
        </div>{" "}
        <NextLevel />
      </>
    );
}
