import { createMedia } from "@artsy/fresnel";
import React, { Component } from "react";
import AliceCarousel from "react-alice-carousel";
import { connect } from "react-redux";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Image,
  Segment,
} from "semantic-ui-react";
import NextLevel from "../components/NextLevel";
import { fetchAnalysis } from "../redux/actions/actions";

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
});
export class AIVision_kr extends Component {
  componentDidMount() {
    this.props.dispatch(fetchAnalysis());
  }
  render() {
    return (
      <>
        <div style={{ backgroundColor: "#f1f1f1" }}>
          <Container textAlign="center" style={{ paddingTop: "3em" }}>
            <Header as="h3">MUSE API 03</Header>
            <Header as="h1" style={{ fontSize: "48px" }}>
              MUSE AI Vision API
            </Header>
            {/* <p>지금 MUSE Vision API를 체험해 보세요</p> */}
          </Container>

          <Container style={{ padding: "2em" }}>
            <Segment raised style={{ paddingTop: 40 }} textAlign="center">
              {/* <Visualization /> */}

              <Header>팜유 열매의 숙성도 탐지에 활용</Header>
              <AliceCarousel autoPlay autoPlayInterval="3000">
                <Image src="/palm1.png" />
                <Image src="/palm3.png" />
                <Image src="/palm4.png" />
                <Image src="/palm5.png" />
              </AliceCarousel>
              <Divider hidden />

              <Button
                color="orange"
                href="https://www.youtube.com/watch?v=I0WporBtMn8"
              >
                동영상 보기
              </Button>
              <Divider />
              <Header>
                식내식물 자동탐지 및 식별에 활용
              </Header>
              <AliceCarousel autoPlay autoPlayInterval="3000">
                <Image src="/indoor_plant_detection.png" />
                <Image src="/indoor_plant_analysis.png" />
              </AliceCarousel>
              <Divider hidden />

              <Button
                href="https://www.youtube.com/watch?v=GpN6yfDqhAY"
                color="orange"
              >
                동영상 보기
              </Button>
            </Segment>
          </Container>
        </div>
        <Divider hidden />
        <Divider hidden />

        <Header as="h1" textAlign="center" style={{ fontSize: "48px" }}>
          Muse AI Vision API
        </Header>
        <Divider hidden />
        <Divider hidden />

        <div style={{ padding: 50 }}>
          <Grid container stackable verticalAlign="top">
            <Grid.Row columns={3}>
              <Grid.Column>
                <Image width="90px" src="/Framevision1.png" />
                <Header style={{fontSize:"28px"}}>사람 표정 분석</Header>
                <p style={{fontSize:"24px"}}>
                  AI가 인식하는 사용자 매칭 및 사용자의 표정 인식{" "}
                </p>
              </Grid.Column>
              <Grid.Column>
                <Image width="90px" src="/Framevision2.png" />
                <Header style={{fontSize:"28px"}}>사물 분석</Header>
                <p style={{fontSize:"24px"}}> 
                  AI가 인식하는 대상이 사물인지, 사물이라면 어떤 사물인지 분석
                </p>
              </Grid.Column>
              <Grid.Column>
                <Image width="90px" src="/Framevision3.png" />
                <Header style={{fontSize:"28px"}}>이미지 분석</Header>
                <p style={{fontSize:"24px"}}>
                  인식하는 전체적인 상황 인식 및 묘사
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider hidden />

          <Grid container stackable verticalAlign="top">
            <Grid.Row columns={3}>
              <Grid.Column>
                <Image width="90px" src="/Framevision4.png" />
                <Header style={{fontSize:"28px"}}>사람 인식</Header>
                <p style={{fontSize:"24px"}}> 
                  인식하는 대상이 사람인지 구별하여 인식
                </p>
              </Grid.Column>
              <Grid.Column>
                <Image width="90px" src="/Framevision5.png" />
                <Header style={{fontSize:"28px"}}>식물 객체 인식</Header>
                <p style={{fontSize:"24px"}}>
                  인식하는 대상이 식물이거나 열매인지 구별하여 인식
                </p>
              </Grid.Column>
              <Grid.Column>
                <Image width="90px" src="/Framevision6.png" />
                <Header style={{fontSize:"28px"}}>식물의 상태 인식</Header>
                <p style={{fontSize:"24px"}}>
                  인식하는 식물의 상태, 수확적합시기 등을 분석하여 통합적으로 인식
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <Divider hidden />
        <Divider hidden />

        <NextLevel />
      </>
    );
  }
}

AIVision_kr = connect((store) => {
  return {
    data: store.data,
  };
})(AIVision_kr);
