import { color } from "echarts";
import React from "react";
import { Container, Grid, Header, List, Segment, Accordion, Icon } from "semantic-ui-react";

export default function Footer_kr() {
  const [open, setOpen] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState();

  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index

    setActiveIndex(newIndex);
  }

  return (
    <Segment vertical style={{ padding: "5em 0em" }} inverted>
        <div style={{ paddingLeft: "32px" }}>
        <Accordion inverted className="hide-in-desktop">
            <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={handleClick}
            >
                <Icon name='dropdown' />
                제품 소개
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
            <div style={{ paddingLeft: "20px" }}>
                <List link inverted>
                    <List.Item as="a" href="/kr/">
                        Muse API
                    </List.Item>
                    <List.Item as="a" href="/kr/ai/analysis/">
                        Muse 분석 API
                    </List.Item>
                    <List.Item as="a" href="/kr/ai/chat/">
                        Muse 챗 API
                    </List.Item>
                    <List.Item as="a" href="/kr/ai/vision/">
                        Muse 비전 API
                    </List.Item>
                </List>
            </div>
            </Accordion.Content>

            <Accordion.Title
                active={activeIndex === 1}
                index={1}
                onClick={handleClick}
            >
            <Icon name='dropdown' />
                솔루션
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
            <div style={{ paddingLeft: "20px" }}>
                <List link inverted>
                    <List.Item as="a" href="/kr/solution/education">
                        교육
                    </List.Item>
                    <List.Item as="a" href="/kr/solution/enterprise">
                        기업용
                    </List.Item>
                    <List.Item as="a" href="/kr/solution/health">
                        헬스케어
                    </List.Item>
                    <List.Item as="a" href="/kr/solution/mobility">
                        모빌리티
                    </List.Item>
                </List>
            </div>
            </Accordion.Content>

            <Accordion.Title
                active={activeIndex === 2}
                index={2}
                onClick={handleClick}
            >
            <Icon name='dropdown' />
                가격 안내
            </Accordion.Title>
            <Accordion.Content>
            <div style={{ paddingLeft: "20px" }}>
                {/* Here will be Pricing Content */}
            </div>
            </Accordion.Content>

            <Accordion.Title
                active={activeIndex === 3}
                index={3}
                onClick={handleClick}
            >
            <Icon name='dropdown' />
                AKA
            </Accordion.Title>
            <Accordion.Content>
            <div style={{ paddingLeft: "20px" }}>
                {/* Here will be Company Content */}
            </div>
            </Accordion.Content>
        </Accordion>
        </div>
      <Container className="hide-in-mobile">
        <Grid stackable>
          <Grid.Row columns={4}>
            <Grid.Column>
              <Header as="a" content="제품 소개" inverted />
              <List link inverted>
                <List.Item as="a" href="/kr/">
                  Muse API
                </List.Item>
                <List.Item as="a" href="/kr/ai/analysis/">
                  Muse 분석 API
                </List.Item>
                <List.Item as="a" href="/kr/ai/chat/">
                  Muse 챗 API
                </List.Item>
                <List.Item as="a" href="/kr/ai/vision/">
                  Muse 비전 API
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <Header as="a" content="솔루션" inverted />
              <List link inverted>
                <List.Item as="a" href="/kr/solution/education">
                  교육
                </List.Item>
                <List.Item as="a" href="/kr/solution/enterprise">
                  기업용
                </List.Item>
                <List.Item as="a" href="/kr/solution/health">
                  헬스케어
                </List.Item>
                <List.Item as="a" href="/kr/solution/mobility">
                  모빌리티
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <Header as="a" href="/kr/pricing" content="가격 안내" inverted />
            </Grid.Column>
            <Grid.Column>
              <Header
                as="a"
                href="https://www.akaintelligence.com/"
                content="AKA"
                inverted
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
}
