import React, { Component } from "react";
import {
  Accordion,
  Button,
  Grid,
  Header,
  Icon,
  Image,
  List,
} from "semantic-ui-react";

const accordionStyle = {
  marginRight: "10%",
  borderLeft: "solid #f37021 3px",
  borderRight: "solid #d1d6db 1px",
  borderTop: "solid #d1d6db 1px",
  borderBottom: "solid #d1d6db 1px",
  padding: "15px",
};
export default class SolutionAccordion extends Component {
  state = { activeIndex: 0 };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;

    return (
      <Grid stackable verticalAlign="top">
        <Grid.Row columns={2}>
         {!this.props.isMobile&&  <Grid.Column>
            {activeIndex === 0 && (
              <Image src="/solution_accordion_education.png" className="hide-in-mobile" />
            )}
            {activeIndex === 1 && (
              <Image src="/solution_accordion_enterprise.png" className="hide-in-mobile" />
            )}
            {activeIndex === 2 && (
              <Image src="/solution_accordion_healthcare.png" className="hide-in-mobile" />
            )}
            {activeIndex === 3 && (
              <Image src="/solution_accordion_mobility.png" className="hide-in-mobile" />
            )}
          </Grid.Column>}
         
          <Grid.Column>
            <Accordion fluid>
              {" "}
              <Accordion.Title
                as={Header}
                active={activeIndex === 0}
                index={0}
                onClick={this.handleClick}
                style={{fontSize:"28px"}}
              >
                <Icon name="dropdown" />
                  교육
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 0}>
                <div style={accordionStyle}>
                  <p style={{fontSize:"24px"}}>원어민 영어 강사 보조</p>
                  <List bulleted style={{fontSize:"24px"}}>
                    <List.Item>MUSE 교육용 API</List.Item>
                    <List.Item>뮤지오 잉글리시</List.Item>
                    <List.Item>컨텐츠 출판 & 파트너쉽</List.Item>
                  </List>
                <a href="/kr/solution/education" style={{ color: "#F2711C", fontSize:"24px" }}>
                    더 알아보기 <Icon name="arrow right" />
                </a>
                </div>
              </Accordion.Content>
              <Accordion.Title
                as={Header}
                active={activeIndex === 1}
                index={1}
                onClick={this.handleClick}
                style={{fontSize:"28px"}}
              >
                <Icon name="dropdown" />
                비즈니스
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 1}>
                <div style={accordionStyle}>
                  <p style={{fontSize:"24px"}}>기업의 인력 보완</p>
                  <List bulleted style={{fontSize:"24px"}}>
                    <List.Item>Muse J X Ken Robotech</List.Item>
                    <List.Item>Muse J X VAIO</List.Item>
                  </List>
                  <a href="/kr/solution/enterprise" style={{ color: "#F2711C", fontSize:"24px" }}>
                    더 알아보기 <Icon name="arrow right" />
                  </a>
                </div>
              </Accordion.Content>
              <Accordion.Title
                as={Header}
                active={activeIndex === 2}
                index={2}
                onClick={this.handleClick}
                style={{fontSize:"28px"}}
              >
                <Icon name="dropdown" />
                  헬스케어
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 2}>
                <div style={accordionStyle}>
                  <p style={{fontSize:"24px"}}>간병인력 보완</p>
                  <List bulleted style={{fontSize:"24px"}}>
                    <List.Item>ReSmart</List.Item>
                    <List.Item>뮤지오 얼라이브 X 선택적 함구증</List.Item>
                  </List>
                  <a href="/kr/solution/health" style={{ color: "#F2711C", fontSize:"24px" }}>
                    더 알아보기 <Icon name="arrow right" />
                  </a>
                </div>
              </Accordion.Content>
              <Accordion.Title
                as={Header}
                active={activeIndex === 3}
                index={3}
                onClick={this.handleClick}
                style={{fontSize:"28px"}}
              >
                <Icon name="dropdown" />
                모빌리티
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 3}>
                <div style={accordionStyle}>
                  <p style={{fontSize:"24px"}}>운전자 보조</p>
                  <List bulleted style={{fontSize:"24px"}}>
                    <List.Item>드라이빙 컴패니언</List.Item>
                    <List.Item>MUSE 메모리 구조</List.Item>
                  </List>
                  <a href="/kr/solution/mobility" style={{ color: "#F2711C", fontSize:"24px" }}>
                    더 알아보기 <Icon name="arrow right" />
                  </a>
                </div>
              </Accordion.Content>
            </Accordion>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
