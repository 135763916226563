import { createMedia } from "@artsy/fresnel";
import React, { Component } from "react";
import {
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Segment,
} from "semantic-ui-react";
import NextLevel from "../components/NextLevel";
import "../assets/global/components/education.css"
import { useMediaQuery } from 'react-responsive'

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
});

const click = ()=> {
  document.location.assign('https://launcher.akaai.io/sales/')
}

export function SolutionEducation() {

  const isMobile = useMediaQuery({query: '(max-width: 768px)'})

  return (
      <>
       <Segment
          style={{
            border: 0,
            padding: "0em 0em 0em 0em",
            backgroundColor: "#ffeee4",
          }}
          vertical
        >
          {!isMobile &&
            <Grid container stackable verticalAlign="middle">
            <Grid.Row style={{ height: "500px" }} columns={1}>
              <Grid.Column width={8}>
                <Header style={{ fontSize: '28px', color: '#F37021', marginTop:"50px" }}>EDUCATION </Header>
                <Header style={{ fontSize: '48px' }}>
                  Supplement the Native English Teacher
                </Header>
                <Button style={{marginTop:'40px'}} color="orange" className="huge ui button" href="/sales/">Talk to Sales</Button>
              </Grid.Column>
              <Grid.Column style={{ marginLeft: "200px", marginTop:"-300px"}}>
                <Image src="/edu-img.png" style={{width:'1000px', height:'536px'}}/>
              </Grid.Column>
            </Grid.Row>
          </Grid> 
          }
          {isMobile && 
            <Grid container stackable verticalAlign="middle">
              <Grid.Row columns={2}>
                <Grid.Column style={{textAlign:"center"}}>
                  <Header as="h3" style={{marginTop:"15px", textAlign:"center"}}>EDUCATION </Header>
                  <Header style={{ fontSize: '28px' }}>
                    Supplement the Native English Teacher
                  </Header>
                  <Image style={{width:"311px", margin:"auto"}} src="/edu_img_mobile.png" />
                  <Button style={{marginTop:'40px'}} color="orange" className="huge ui button" href="/sales/">Talk to Sales</Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          }
          
        </Segment>

        <div className='solution-divider'></div>

        <h1 className='pageTopParth1' style={{textAlign:"center"}}>
          Education
        </h1>

        <Divider hidden />

        <Grid container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Image style={{width:isMobile ? "311px" : "auto" , margin:"auto"}} src="/edugirl.png" />
            </Grid.Column>

            <Grid.Column>
              <Header as="h3" style={{marginTop:"40px"}}>EDUCATION</Header>
              <Header as="h1" >MUSE Educational API</Header>
              <p style={{fontSize:"24px"}}>
                Engage students with Muse's comprehensive communication abilities. Muse is the perfect native English teacher, understanding text, oral language, physical gestures, and even facial expressions.
              </p>
              <List bulleted style={{fontSize:"24px"}}>
                <List.Item>
                  Muse API X Chungdahm Learning
                  <List.List>
                    <List.Item>Vena Talk</List.Item>
                    <List.Item>Over 96% conversion rate</List.Item>
                  </List.List>
                </List.Item>
                <List.Item>
                  Muse API X Softbank Robotics China
                  <List.List>
                    <List.Item>Pepper</List.Item>
                    <List.Item>Over 2000 Pepper units</List.Item>
                  </List.List>
                </List.Item>
                <List.Item>Muse X Oxford University Press</List.Item>
              </List>
              {
                !isMobile &&
                <a href="https://launcher.akaai.io/" style={{ color: "#F2711C", fontSize:"24px" }}>
                Learn more <Icon name="arrow right" />
              </a>}

              {isMobile && <Divider hidden />}
              {isMobile && <Divider hidden />}
              {isMobile &&   <a  className='mobileLinkMainWatchVideo' href="/ai/chat/" style={{ color: "#F2711C" }}>Go to the Website <Icon name="arrow right" /></a>}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />

        {isMobile &&
            <Grid.Column>
              <Image style={{width:isMobile ? "311px" : "auto" , margin:"auto"}} src="/edu2.png" />
          </Grid.Column>}
          {isMobile && <Divider hidden />}
          {isMobile && <Divider hidden />}
        <Grid container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header as="h3" style={{marginTop:"40px"}}>EDUCATION</Header>
              <Header as="h1">Musio English</Header>
              <p style={{fontSize:"24px"}}>
                Musio is the only verified AI robot in public schools. 
                Powered by Muse, Musio is used in over 210 institutions in Japan and South Korea, 
                with over 70% of Musio users showing increased usage and confidence in English.
              </p>
              <List bulleted style={{fontSize:"24px"}}>
                <List.Item>Muse API X Cambridge University Press</List.Item>
                <List.Item>
                  Muse API X Online Video English
                  <List.List>
                    <List.Item>Musio X Kimini (Gakken)</List.Item>
                    <List.Item>64.2% CVR</List.Item>
                  </List.List>
                </List.Item>
              </List>
              {isMobile && <Divider hidden />}
              {isMobile && <Divider hidden />}
              {!isMobile &&  <a href="https://musioenglish.kr/" style={{ color: "#F2711C" ,fontSize:"24px"}}>
                Go to the website <Icon name="arrow right" />
              </a>}
              {isMobile &&   <a  className='mobileLinkMainWatchVideo' href="/ai/chat/" style={{ color: "#F2711C" }}>Go to the Website <Icon name="arrow right" /></a>}

            </Grid.Column>

            {!isMobile &&
            <Grid.Column>
              <Image style={{width:isMobile ? "311px" : "auto" , margin:"auto"}} src="/edu2.png" />
            </Grid.Column>}
          </Grid.Row>
        </Grid>

        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />

        <Grid container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Image style={{width:isMobile ? "311px" : "auto" , margin:"auto"}} src="/edu3.png" />
            </Grid.Column>

            <Grid.Column>
              <Header as="h3">EDUCATION</Header>
              <Header as="h1">Contents Publishing & Partnerships</Header>
              <List bulleted style={{width:"100%", fontSize:"24px", lineHeight:"150%" }}>
                AKA can digitize education content and offer them as AI-led classes (180 educational books).<br/>
                
                High-quality self-produced education, led by a co-founder of a top-tier education company in Korea (Hackers Edu. Group)<br/>
                Partnership objectives & outcomes
                <List.Item>
                Partnering with best-selling education companies (local market)
                </List.Item>
                <List.Item>Content development with publishers</List.Item>
                <List.Item>Provide various levels for target users</List.Item>
              </List>
              {isMobile && <Divider hidden />}
              {isMobile && <Divider hidden />}
              {!isMobile &&  <a href="#" style={{ color: "#F2711C", fontSize:"24px" }}>Learn more <Icon name="arrow right" /></a>}
              {isMobile &&   <a  className='mobileLinkMainWatchVideo' href="/ai/chat/" style={{ color: "#F2711C" }}>Go to the Website <Icon name="arrow right" /></a>}

            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div>
        <Divider hidden />
        <Divider hidden />

        <Divider hidden />
        <Divider hidden />
        </div>
        <Segment
          style={{ padding: isMobile ? "3em 0em" : "8em 0em", border: 0, backgroundColor: "#ffeee4" }}
          vertical
        >

          <Grid container stackable verticalAlign="middle">
            <Grid.Row columns={2}>
              {isMobile &&
                <Grid.Column>
                <Image style={{width:isMobile ? "103px" :"auto", float:'right'}} src="/edu5.png" />
                <Image  src="/edu4.png" />
              </Grid.Column>}

              <Grid.Column>
                <Header as="h3">Muse API Success Case</Header>
                <Header as="h1">Chungdahm Learning Vena Talk</Header>
                <p style={{fontSize: '24px'}}>
                  VENA TALK is a Muse-powered, corpus-based English education app with unlimited free-chat for children. In three steps, 1. Practice, 2. Role Play, and 3. Free Talk, students learn expressions, patterns, and how to talk freely.
                </p>
                

                {!isMobile &&  <a href="https://apps.apple.com/kr/app/april%EB%B9%84%EB%82%98%ED%86%A1/id1486317712" style={{ color: "#F2711C", fontSize:"24px" }}>
                    Check it out <Icon name="arrow right" />
                </a>}
                {isMobile && <Divider hidden />}
                {isMobile && <Divider hidden />}
              {isMobile &&   <a  className='mobileLinkMainWatchVideo' href="/ai/chat/" style={{ color: "#F2711C" }}>Watch a video <Icon name="arrow right" /></a>}

              </Grid.Column>
                {!isMobile &&
                <Grid.Column>
                <Image src="/edu5.png" />
                <Image src="/edu4.png" />
              </Grid.Column>}
            </Grid.Row>

            {isMobile &&
            <div style={{width:"100%", display:"flex" , margin:"auto", justifyContent:"space-between"}} >
                <Image width="76px" src="/icon1.png" />
                <Image width="110px" src="/icon2.png" />
                <Image  width="75px"src="/icon3.png" />
            </div>
            }
            {!isMobile &&
            <Grid.Row columns={3}>
              <Grid.Column>
                <Image src="/icon1.png" />
              </Grid.Column>
              <Grid.Column>
                <Image src="/icon2.png" />
              </Grid.Column>
              <Grid.Column>
                <Image src="/icon3.png" />
              </Grid.Column>
            </Grid.Row>
            }
          </Grid>
        </Segment>
        <NextLevel />
      </>
    )
}
