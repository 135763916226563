import { createMedia } from "@artsy/fresnel";
import React, { Component } from "react";
import {
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  Segment,
} from "semantic-ui-react";
import NextLevel from "../components/NextLevel";
import { useMediaQuery } from 'react-responsive'

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
});
export function SolutionHealth_kr() {
  const isMobile = useMediaQuery({query: '(max-width: 768px)'})
  
    return (
      <>
        <Segment
          style={{
            border: 0,
            padding: "0em 0em 0em 0em",
            backgroundColor: "#ffeee4",
          }}
          vertical
        >
          {!isMobile &&
            <Grid container stackable verticalAlign="middle">
              <Grid.Row style={{ height: "500px" }} columns={1}>
                <Grid.Column width={8}>
                  <Header style={{fontSize: '28px', color: '#F37021', marginTop:"50px"}}>
                    헬스케어
                  </Header>
                  <Header style={{fontSize: '48px'}}>
                    간병인력 보완
                  </Header>
                  <Button style={{marginTop:'40px'}}color="orange" className="huge ui button" href="/sales/">구매 상담</Button>
                </Grid.Column>
                <Grid.Column style={{ marginLeft: "200px", marginTop:"-300px"}}>
                  <Image src="/healthsvg.png" style={{width:'1000px', height:'536px', marginTop:'50px'}} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            }

            {isMobile && 
            <Grid container stackable verticalAlign="middle">
              <Grid.Row columns={2}>
                <Grid.Column style={{textAlign:"center"}}>
                  <Header as="h3" style={{marginTop:"15px", textAlign:"center"}}>헬스케어 </Header>
                  <Header style={{ fontSize: '28px' }}>
                  간병인력 보완
                  </Header>
                  <Image style={{width:"311px", margin:"auto"}} src="/health-mobile.png" />
                  <Button style={{marginTop:'40px'}} color="orange" className="huge ui button" href="/sales/">구매 상담</Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          } 
        </Segment>

        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />

        <Header as="h1"  className='pageTopParth1' textAlign="center" style={{marginTop:"100px" }}>
          헬스케어
        </Header>

        <Divider hidden />

        <Grid container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Image src="/health1.png" />
            </Grid.Column>

            <Grid.Column>
              <Header as="h3" style={{marginTop:"100px"}}>헬스케어</Header>
              <Header as="h1">ReSmart</Header>
              <p style={{fontSize:"24px"}}>
                인공지능을 개선시키는 두뇌 훈련 프로그램, 명상과 권장 일일 활동들로 AI 생활코칭을 제공합니다.
              <br/><br/>
              5가지 주요 인지기능을 개선<br/><br/>
              스트레스와 그로 인한 우울증 발병률을 낮춤<br/><br/>
          
              몸과 두뇌 건강을 유지시키기 위한 일일 활동 제공<br/><br/>
              
              개선 과정 기록 </p><br/>

              <a href="https://akaon.com/resmart" style={{ color: "#F2711C", fontSize:"24px" }}>
                더 알아보기 <Icon name="arrow right" />
              </a>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Divider hidden />

        <Divider hidden />
        <Divider hidden />

        <Grid container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column style={{marginTop:"100px"}}>
              <Header as="h3">헬스케어</Header>
              <Header as="h1">뮤지오 얼라이브 X 선택적 함구증</Header>
              <p style={{fontSize:"24px"}}>
                AI 로봇을 활용한 장애 학생 교육의 효과가 일본의 한 중학교에서 입증되었습니다. 선택적 함구증을 극복하고 보다 자유로운 대화를 하는 데 AI 로봇이 도움을 줄 수 있는 것으로 밝혀졌습니다.{" "}
              </p>
              <p style={{fontSize:"24px"}}>
                처음에는 외국어를 배우는 것에 대해 수동적이었던 학생들이 학습에 더 관심을 갖게 되었습니다. 수업 태도 또한 개선되어 발음 연습에 더 열심히 임하는 모습을 보였습니다.{" "}
              </p>
              <a href="https://akaon.com/resmart" style={{ color: "#F2711C",fontSize:"24px" }}>
                더 알아보기 <Icon name="arrow right" />
              </a>
            </Grid.Column>

            <Grid.Column>
              <Image src="/health2.png" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Divider hidden />

        <Divider hidden />
        <Divider hidden />

        <NextLevel />
      </>
    );
  
}
