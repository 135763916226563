import { createMedia } from "@artsy/fresnel";
import React, { Component } from "react";
import {
  Button,
  Divider,
  Grid,
  Header,
  Image,
  Segment,
} from "semantic-ui-react";
import NextLevel from "../components/NextLevel";
import { useMediaQuery } from 'react-responsive'

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
});
export function SolutionMobility_jp() {

  const isMobile = useMediaQuery({query: '(max-width: 768px)'})
  
    return (
      <>
        <Segment
          style={{
            border: 0,
            padding: "0em 0em 0em 0em",
            backgroundColor: "#ffeee4",
          }}
          vertical
        >
          {!isMobile &&
            <Grid container stackable verticalAlign="middle">
              <Grid.Row style={{ height: "500px" }} columns={1}>
                <Grid.Column width={5}>
                  <Header style={{ fontSize: '28px', color: '#F37021', marginTop:"50px" }}>モビリティ </Header>
                  <Header style={{ fontSize: '48px' }}>
                  人間の運転者を補う
                  </Header>
                  <Button style={{marginTop:'40px'}} color="orange" className="huge ui button" href="/sales/">お問い合わせ</Button>
                </Grid.Column>
                <Grid.Column style={{ marginLeft: "200px", marginTop:"-350px"}}>
                  <Image src="/mobility1.png" style={{width:'1000px', height:'536px'}} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            }
            {isMobile &&
              <Grid container stackable verticalAlign="middle">
              <Grid.Row columns={2}>
                <Grid.Column style={{textAlign:"center"}}>
                  <Header as="h3" style={{marginTop:"15px", textAlign:"center"}}>モビリティ  </Header>
                  <Header style={{ fontSize: '28px' }}>
                  人間の運転者を補う
                  </Header>
                  <Image style={{width:"311px", margin:"auto"}} src="/mobility-mobile.png" />
                  <Button style={{marginTop:'40px'}} color="orange" className="huge ui button" href="/sales/">お問い合わせ</Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            }
        </Segment>

        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />

        <Header as="h1" textAlign="center" style={{ fontSize: "100px" }}>
        モビリティ{" "}
        </Header>

        <Divider hidden />

        <Grid container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header as="h3">モビリティ</Header>
              <Header as="h1">同行者（ドライビングコンパニオン）</Header>
              <p style={{fontSize:"24px"}}>Museはメモリベースの会話生成器です。
              <br/><br/>
              Museは、独自の人工プロセスから得た経験を通じて、車と人の間の自然で直感的なコミュニケーションを可能にします。
              Museは運転者とコミュニケーションをとったり、運転者自身の意見を伝えたりするだけでなく、運転者の感情を認識して共感します。
              </p>
            </Grid.Column>
            <Grid.Column>
              <Image src="/mobility2.png" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Divider hidden />

        <Divider hidden />
        <Divider hidden />

        <Grid container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Image src="/mobility3.png" />
            </Grid.Column>

            <Grid.Column>
              <Header as="h3" style={{marginTop:"40px"}}>モビリティ</Header>
              <Header as="h1">MUSEのメモリ構造</Header>
              <p style={{fontSize:"24px"}}>文章から重要な内容を抽出</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Divider hidden />

        <Divider hidden />
        <Divider hidden />

        <NextLevel />
      </>
    );
  
}
