import { createMedia } from "@artsy/fresnel";
import React, { Component } from "react";
import {
  Button,
  Divider,
  Grid,
  Header,
  Image,
  Segment,
} from "semantic-ui-react";
import NextLevel from "../components/NextLevel";
import { useMediaQuery } from 'react-responsive'

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
});
export function SolutionMobility_kr() {
  
  const isMobile = useMediaQuery({query: '(max-width: 768px)'})

    return (
      <>
        <Segment
          style={{
            border: 0,
            padding: "0em 0em 0em 0em",
            backgroundColor: "#ffeee4",
          }}
          vertical
        >
          {!isMobile && 
            <Grid container stackable verticalAlign="middle">
              <Grid.Row style={{ height: "500px" }} columns={1}>
                <Grid.Column width={5}>
                  <Header style={{ fontSize: '28px', color: '#F37021', marginTop:"50px" }}>모빌리티 </Header>
                  <Header style={{ fontSize: '48px' }}>
                    운전자 보조
                  </Header>
                  <Button style={{marginTop:'40px'}} color="orange" className="huge ui button" href="/sales/">구매 상담</Button>
                </Grid.Column>
                <Grid.Column  style={{ marginLeft: "200px", marginTop:"-300px"}}>
                  <Image src="/mobility1.png" style={{width:'1000px', height:'536px'}}/>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          }
          {isMobile &&
            <Grid container stackable verticalAlign="middle">
            <Grid.Row columns={2}>
              <Grid.Column style={{textAlign:"center"}}>
                <Header as="h3" style={{marginTop:"15px", textAlign:"center"}}>모빌리티  </Header>
                <Header style={{ fontSize: '28px' }}>
                운전자 보조
                </Header>
                <Image style={{width:"311px", margin:"auto"}} src="/mobility-mobile.png" />
                <Button style={{marginTop:'40px'}} color="orange" className="huge ui button" href="/sales/">구매 상담</Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          }
        </Segment>

        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />

        <Header as="h1" className='pageTopParth1' textAlign="center" style={{ marginTop:"100px" }}>
          모빌리티{" "}
        </Header>

        <Divider hidden />

        <Grid container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header as="h3">모빌리티</Header>
              <Header as="h1">드라이빙 컴패니언</Header>
              <p style={{fontSize:"24px"}}>뮤즈는 메모리 기반 대화생성기입니다. 
              <br/><br/>
                뮤즈는 자체 인공 프로세스에서 쌓은 경험을 통해 자동차와 사람 사이의 직관적인 대화가 가능하게 합니다. 뮤즈는 운전자와 소통하고 자기의 의견을 전달할 뿐만 아니라 운전자의 감정을 인식하고 이에 공감할 수 있습니다.{" "}
              </p>
            </Grid.Column>
            <Grid.Column>
              <Image src="/mobility2.png" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Divider hidden />

        <Divider hidden />
        <Divider hidden />

        <Grid container stackable verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Image src="/mobility3.png" />
            </Grid.Column>

            <Grid.Column>
              <Header as="h3" style={{marginTop:"40px"}}>모빌리티</Header>
              <Header as="h1">MUSE의 메모리 구조</Header>
              <p style={{fontSize:"24px"}}>문장에서 의미있는 부분들을 추출</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Divider hidden />

        <Divider hidden />
        <Divider hidden />

        <NextLevel />
      </>
    );
  }
